.object {
    &-header {
        margin-bottom: 20px;
        position: relative;
        &__image {
            &-swiper {
                &.hide {
                    visibility: hidden;
                    z-index: -1;
                }
            }
            .no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 50vw;
                min-height: 200px;
                max-height: 400px;
                margin-bottom: 20px;
            }
            &-main {
                width: 100%;
                height: 400px;
                display: flex;
                justify-content: center;
                position: relative;
                &.fullwidth {
                    width: 100%;
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    max-height: 360px;
                    object-fit: contain;
                }
                video,
                audio {
                    max-width: 100%;
                }
            }
            &-nav {
                width: calc(100% + 20px);
                height: 120px;
                margin-top: 10px;
                display: flex;
                position: relative;
                &--loading {
                    position: absolute;
                    width: calc(100% - 20px);
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .loader {
                        width: 40px;
                        height: 40px;
                        max-width: none;
                        position: unset;
                        display: block;
                    }
                    &.hide {
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                }
                .swiper-slide {
                    position: relative;
                    margin-right: 10px;
                    min-width: 20px;
                    width: fit-content;
                    max-width: 100%;
                    &:last-of-type {
                        margin-right: 20px;
                    }
                }
                img {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
            &-overlay {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: opacity .3s ease-in-out;
                background-color: rgba(0, 0, 0, .3);
                &:hover {
                    opacity: 1;
                }
                &:focus-visible {
                    opacity: 1;
                }
            }
            .image-wrapper {
                position: relative;
                max-width: 100%;
                height: calc(100% - 40px);
                padding: 20px;
            }
        }
        &--text {
            font-size: 11px;
            line-height: 17px;
            text-align: center;
            letter-spacing: -.25px;
            width: 100%;
            margin-top: 5px;
            text-decoration: underline;
            text-underline-offset: 4px;
        }
        &--arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            > .btn {
                position: relative;
            }
        }
        &--prev {
            margin-right: 30px;
            .tooltip {
                &:before {
                    left: 38px;
                }
            }
        }
        &--next {
            margin-left: 30px;
            .tooltip {
                &:before {
                    right: 38px;
                }
            }
        }
        .fav-remove {
            svg path {
                fill: $clay-grey;
            }
            &:hover, &:focus {
                svg path {
                    fill: $stone-black;
                }
            }
            svg.fill-black {
                &:hover, &:focus {
                    path {
                        fill: $stone-black;
                    }
                }
            }
        }
        svg {
            cursor: pointer;
            &.stroke-black:hover,
            &.stroke-black:focus {
                line,
                path,
                circle {
                    stroke: $stone-black;
                    stroke-width: 2px;
                }
            }
            &.fill-black:hover,
            &.fill-black:focus {
                path,
                circle {
                    fill: $stone-black;
                }
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            margin: 20px 0;
            &--links {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                &-icons {
                    display: flex;
                    align-items: center;
                    margin: 10px 0 14px;
                    padding-top: 8px;
                    gap: 20px;
                    .fav-icon {
                        max-width: 18px;
                        max-height: 17px;
                    }
                    position: relative;
                    // just for mobile tweaks for fist two children
                    @media screen and (max-width: $break-tablet-max) {
                        .tooltip-top {
                            &:before {
                                left: 0;
                            }
                            &.copyURL {
                                &:before {
                                    left: auto;
                                }
                            }
                        }
                    }
                }
                p {
                    @extend .typography-8;
                    color: $caption-grey;
                    a {
                        color: $stone-black;
                        text-decoration: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 4px;
                        cursor: pointer;
                        &:hover {
                            color: $concrete-grey;
                        }
                    }
                }
            }
            &--title {
                @extend .typography-3;
                display: flex;
                flex-direction: column;
                gap: 4px;
                /* identical to box height, or 130% */
                h3 {
                    font-style: italic;
                }
                p {
                    color: $concrete-grey;
                }
            }
        }
    }
    &-body {
        .accordion-container {
            .accordion {
                &-header {
                    border-top: 1px solid $rubber-grey;
                    padding: 10px 0;
                    h6 {
                        @extend .typography-6;
                    }
                    &:last-child {
                        border-bottom: 1px solid $rubber-grey;
                    }
                }
                >p {
                    @extend .typography-7;
                    margin-bottom: 20px;
                    &:first-child {
                        padding-top: 20px;
                        border-top: 1px solid $rubber-grey;
                    }
                }
            }
        }
        &+.carousel {
            margin-top: 50px;
        }
    }
    &-disclaimer {
        margin: 20px 0;
        @extend .typography-8;
        color: $concrete-grey;
        a {
            text-decoration: underline;
            text-underline-offset: 4px;
            color: $stone-black;
            text-decoration-thickness: 1px;
            &:hover {
                color: $concrete-grey;
            }
        }
    }
    &-description {
        .accordion {
            max-height: 120px;
            margin-bottom: 5px;
            &-body {
                @extend .typography-5;
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 6em;
            }

            &-toggle {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                .prompt {
                    @extend .typography-6;
                }

                svg {
                    margin-right: 4px;
                }
                .prompt {
                    
                    text-decoration: underline;
                    text-decoration-color: transparent;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }

                &:hover .prompt{
                    color: $concrete-grey;
                    text-decoration-color: currentColor;
                }
            }
        }
    }

    &-data {
        margin-bottom: 34px;

        &__label {
            @extend .typography-6;
            color: $concrete-grey;
            margin-bottom: 2px;
        }

        &__value {
            @extend .typography-6;
        }

        a {
            text-decoration: underline;
            text-underline-offset: 4px;
            color: $stone-black;
            text-decoration-thickness: 1px;

            &:hover {
                color: $concrete-grey;
            }
        }

        li {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        &-header {
            &__image {
                &-nav {
                    width: calc(100% + 30px);
                    &--loading {
                        width: calc(100% - 30px);
                    }
                    .swiper-slide {
                        &:last-of-type {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &-header {
            display: flex;
            justify-content: space-between;
            // padding: 0 30px;
            height: 575px;
            margin-bottom: 125px;
            padding-bottom: 60px;
            margin-bottom: 40px;
            &__image {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                width: 50%;
                // width: calc(100% - 110px);
                height: 100%;
                // max-height: none;
                position: relative;
                align-items: center;
                overflow: visible;
                &.no-slider {
                    align-items: flex-end;
                    .object-header__image-swiper--wrapper {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
                .no-image {
                    margin-bottom: 0;
                }
                &-main {
                    width: calc(100% - 110px);
                    height: 575px;
                    align-items: center;
                    img {
                        max-height: 575px;
                    }
                }
                &-nav {
                    width: 90px;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    // align-items: center;
                    position: relative;
                    overflow: hidden;
                    max-height: 575px;
                    margin-top: 0;
                    &--loading {
                        width: 100%;
                    }
                    &:after {
                        display: none;
                    }
                    .swiper-slide {
                        margin-top: 15px;
                        margin-right: 0;
                        height: fit-content;
                        &:first-of-type {
                            margin-top: 0;
                        }
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &-swiper {
                    display: flex;
                }
                .image-wrapper {
                    margin-bottom: 0;
                }
            }
            &--text {
                font-size: 14px;
                line-height: 24px;
                position: absolute;
                bottom: -30px;
                left: 0;
                width: 90px;
            }
            &__info {
                align-items: flex-start;
                justify-content: center;
                width: calc(50% - 50px);
                margin: 0;
                margin-left: 30px;
            }
        }
        &-body {
            // max-width: 924px;
            margin: 0 auto 25px;
            .accordion>p {
                width: 100%;
            }
            .object-description .accordion-body {
                width: 100%;
            }
            &+.carousel {
                margin-top: 100px;
            }
        }
        &-data {
            margin-bottom: 50px;
            li {
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-gap: 20px;
                margin-bottom: 20px;
            }
        }
        &-description {
            width: 70%;
            margin-bottom: 50px;
            .accordion {
                max-height: 160px;
                margin-bottom: 25px;
                &-body {
                    // width: 100%;
                    width: 70%;
                    -webkit-line-clamp: 5;
                    max-height: 8em;
                }
                &-toggle {
                    svg {
                        width: 13px;
                        height: 13px;
                    }
                    
                }
            }
        }
    }
    @media screen and (max-width: $break-tablet-max) {
        &-header {
            &__info {
                &--links {
                    p {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                &--title {
                    font-size: 20px;
                    line-height: 26px;
                    /* identical to box height, or 130% */
                    letter-spacing: -0.5px;
                }
            }
        }
        &-disclaimer {
            font-size: 14px;
            line-height: 16px;
        }

        &-data {
            &__label {
                font-size: 14px;
                line-height: 16px;
            }

            &__value {
                font-size: 17px;
                line-height: 20px;
                /* identical to box height, or 133% */
                letter-spacing: -0.25px;
            }
        }

        &-body {
            .accordion-container {

                .accordion {

                    &-header {
                        h6 {

                            font-size: 15px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: -0.25px;
                        }

                        svg {
                            height: 18px;
                            width: 18px;
                            
                        }
                    }

                    &-toggle {
                        color: $concrete-grey;

                        .prompt {
                            font-size: 15px;
                            line-height: 24px;
                            /* identical to box height, or 129% */
                            letter-spacing: -0.25px;
                        }
                    }

                    &>p {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.25px
                    }

                }
            }
        }
        &-description {
            .accordion {
                &-body {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                }
                &-toggle {
                    color: $concrete-grey;
                    .prompt {
                        font-size: 15px;
                        line-height: 24px;
                        /* identical to box height, or 129% */
                        letter-spacing: -0.25px;
                    }
                }
                & > p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.25px
                }
            }
        }

    }
}

.notes {
    padding-top: 30px;
    p {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.25px;
        margin-bottom: 20px;
    }
    &-item {
        display: flex;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.25px;
        margin-bottom: 20px;
    }
    &-key {
        margin-right: 5px;
    }
}