.filter {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -.25px;
    &--overlay {
        pointer-events: none;
        position: fixed;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 3;
        background-color: $stone-black;
        &.show {
            pointer-events: all;
            opacity: 0.6;
        }
    }
    &--wrapper {
        background-color: white;
        position: relative;
        z-index: 2;
        margin-bottom: 20px;
        &:has(.filter-bottom) {
            margin-bottom: 0;
        }
        &.spacing {
            margin-top: 40px;
            padding-bottom: 17px;
        }
        &.fixed {
            position: sticky;
            z-index: 2;
            width: 100%;
            top: 0;
            left: 0;
            //padding: 10px 0;
            min-height: 52px;
            > section {
                max-width: 1440px;
            }
            .filter-accordion {
                padding-top: 15px;
            }
            .filter-bottom--subfilter {
                //margin-bottom: 10px;
            }
        }
        &+.list-module {
            &:not(.feature-top) {
                margin-top: 20px;
            }
            &.feature-top {
                margin-top: 10px;
            }
        }
        &+.list-calendar {
            margin-top: 5px;
        }
        @media screen and (min-width: $break-tablet-min) {
            &.spacing {
                margin-top: 50px;
            }
        }
        @media screen and (min-width: $break-desktop-min) {
            margin-bottom: 30px;
            &.spacing {
                margin-top: 100px;
                padding-bottom: 1px;
            }
            &.fixed {
                min-height: 62px;
                .filter-accordion {
                    padding-top: 0px;
                }
            }
            &+.list-module {
                &:not(.feature-top) {
                    margin-top: 40px;
                }
                // margin-top: 30px;
            }
        }
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 10px;
        min-height: 37px;
        // &:has(.filter-top--right) {
        //     .filter-top--left {
        //         width: 60%;
        //     }
        // }
        &--left {
            min-width: 200px;
            padding: 10px 0;
            width: 100%;
            &:has(:focus-visible) {
                overflow: visible;
            }
            .basic-search-form {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                svg {
                    margin-right: 5px;
                }
                .search-keyword {
                    width: calc(100% - 20px);
                }
            }
        }
        &--right {
            display: flex;
            align-items: center;
            .dropdown {
                margin-left: 18px;
            }
            a, button {
                &:not(button) {
                    --btn-height: 35px;
                }
                &:not(a) {
                    --btn-height: 37px;
                }
                &.btn:not(.btn-lightbox-form, .sort-selected, .calendar-arrow) {
                    margin-left: 10px;
                    height: var(--btn-height);
                    max-height: var(--btn-height);
                    text-decoration: none;
                    // min-width: 30px;
                    &.tooltip::before {
                        right: 0;
                    }
                    &:hover {
                        svg {
                            fill: $stone-black;
                            path, circle, line {
                                fill: $stone-black;
                            }
                        }
                    }
                    @media screen and (min-width: $break-desktop-min) {
                        &.tooltip {
                            position: relative;
                            &:before {
                                left: auto;
                                right: -30px;
                            }
                        }
                    }
                }
            }
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: -1px;
        padding-bottom: 10px;
        &.tablet-only {
            display: none;
            @media screen and (min-width: $break-tablet-min) {
                display: flex;
                
            }
        }
        &--left {
            .filter-sort--text {
                margin-left: 0;
                margin-right: 5px;
            }
            .sort-list {
                left: 0;
                right: auto;
                &-item {
                    text-align: left;
                    a {
                        text-align: left;
                        padding: 0 30px 0 20px;
                    }
                }
            }
        }
        &--right {
            display: flex;
            .filter-btn {
                margin-left: 10px;
            }
            .dropdown {
                margin-left: 18px;
            }
        }
        &--subfilter {
            .filter-links {
                a {
                    &:hover, &.active {           
                        text-decoration: underline;
                        text-underline-offset: 4px;
                    }
                }
            }
        }
    }
    &-collection {
        //padding: 15px 0;
        .sort-list {
            margin-top: 1px;
        }
        &--wrappper {
            &.fixed {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9;
                background-color: $salt-white;
                width: calc(100vw - 80px);
                padding: 0 40px;
            }
        }
        .filter-top--left {
            width: 60%;
        }
    }
    &-event {
        .filter-top--left {
            width: 60%;
        }
    }
    &-criteria {
        display: flex;
        align-items: center;
        margin: 20px 0;
        &--clear {
            font-size: 9px;
            line-height: 16px;
            letter-spacing: 0.5px;
            margin-top: 5px;
        }
        &-count {
            margin-right: 5px;
            white-space: nowrap;
        }
        &--text {
            display: inline-flex;
            text-decoration: underline;
            text-decoration-color: #fff;
            text-underline-offset: 4px;
            > span {
                // width: 270px;
                max-width: 270px;
                // max-width: calc(100vw - 60px);
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 5px;
            }
        }
        &--list {
            display: flex;
            align-items: center;
            // justify-content: center;
            flex-wrap: wrap;
        }
        &--remove {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        a {
            text-decoration: none;
            color: $stone-black;
            &.filter-criteria--clear {
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
                text-decoration-color: $concrete-grey;
            }
            &:hover {
                .filter-criteria--text {
                    text-decoration-color: currentColor;
                }
                .filter-criteria--value {
                    color: $stone-black;
                }
                &.filter-criteria--clear {
                    color: $stone-black;
                    text-decoration-color: $stone-black;
                }
            }
        }
        li {
            margin-right: 5px;
            svg {
                width: 9px;
                height: 9px;
            }
        }
        svg {
            margin-left: 3px;
        }
        @media screen and (max-width: $break-tablet-max) {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            flex-wrap: wrap;
            margin-top: 0;
            &--list {
                .filter-criteria--clear {
                    display: none;
                }
            }
            p.no-desktop {
                display: flex;
                width: 100%;
                margin-top: 3px;
                justify-content: flex-start;
            }
        }
    }
    &-theme {
        padding-top: 12px;
        margin-bottom: 40px;
        &--name {
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.25px;
            margin-bottom: 10px;
        }
        &--detail {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.25px;
            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.25px;
            }
        }
    }
    &-user {
        margin-bottom: 20px;
    }
    &-exhibition {
        .filter-mobile {
            .filter-bottom {
                justify-content: flex-start;
                margin-bottom: 15px;
            }
            .dropdown {
                margin-right: 10px;
            }
            .filter-sort--text {
                margin: 0;
            }
            &.open {
                .filter-links--underline.active {
                    border: none;
                }
                .dropdown {
                    border-top: 1px solid transparent;
                    &.show {
                        border-color: $stone-black;
                    }
                }
                .sort-list {
                    width: calc(100vw - 40px);
                    left: 0;
                    ul {
                        padding: 0;
                        border: none;
                    }
                }
            }
        }
        .desktop-only {
            .filter-top {
                margin-bottom: 15px;
            }
        }
    }
    &-resource {
        .filter-mobile {
            &:not(.open) {
                .border-bottom {
                    border-bottom: none;
                }
            }
            .filter-top--left {
                display: none;
            }
            &.open {
                .filter-top--left {
                    display: flex;
                }
            }
        }
    }
    &-research {
        @media screen and (max-width: $break-tablet-max) {
            .filter-top {
                margin-bottom: 0;
            }
            .filter-bottom--right {
                display: none;
            }
            .filter-links {
                a {
                    display: block;
                    padding-top: 10px;
                    border-top: 2px solid transparent;
                    white-space: nowrap;
                    &.active, &:hover {
                        border-color: $stone-black;
                    }
                }
            }
        }
        .filter-links {
            li {
                display: flex;
            }
        }
    }
    &-accordion {
        padding-top: 15px;
        .btn {
            &:hover {
                svg path {
                    fill: $stone-black;
                }
            }
            &-request {
                &:hover {
                    color: $salt-white;
                    background-color: $stone-black;
                }
            }
        }
        &--guide {
            display: none;
        }
        .filter-mobile {
            .filter-border-bottom {
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            span {
                text-align: left;
            }
            .filter-top--right {
                .btn {
                    margin-right: 0;
                }
            }
            &.open {
                .filter-accordion--guide {
                    display: inline;
                }
            }
        }
    }
    &-mobile {
        width: 100%;
        // transition: all 0.3s ease-in-out;
        &.open {
            position: fixed;
            top: 0;
            left: 0;
            width: calc(100vw - 40px);
            height: calc(100vh - 30px);
            padding: 15px 20px;
            background-color: white;
            z-index: 10;
            .btn-open {
                display: none;
            }
            .btn-close.active {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: fixed;
                width: 100%;
                top: 0;
                left: 0;
                padding: 20px;
                z-index: 2;
            }
            .filter-mobile--selection {
                display: block;
            }
            .filter-border-bottom {
                display: none;
            }
            .sort-calendar--selected {
                display: none;
            }
            .calendar {
                border: none;
                position: relative;
                height: calc(100vh - 125px);
                &-top {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }
                &-arrows {
                    .btn {
                        margin-right: 0;
                    }
                }
                table {
                    width: calc(100% + 16px);
                    margin-top: 30px;
                    margin-left: -8px;
                }
                td {
                    padding: 10px 8px;
                }
            }
        }
        .autocomplete {
            top: calc(100% + 1px);
            padding-bottom: 25px;
            &-group {
                border-top: none;
                margin-top: 25px;
                &--title {
                    font-size: 11px;
                    line-height: 16px;
                    letter-spacing: -0.25px;
                }
            }
            &-list {
                margin-top: 6px;
                &--item {
                    padding: 10px 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &.autocomplete-list--viewall {
                        padding: 0;
                        margin-top: 8px;
                    }
                }
                &--number, &--viewall-text {
                    font-size: 12px;
                }
            }
        }
        .btn {
            margin-right: 10px;
            svg {
                margin-left: 5px;
            }
            &-close {
                display: none;
                svg {
                    width: 14px;
                    height: 14px;
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
            &-filter-open {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                }
            }
        }
        .filter-top {
            .btn {
                margin-right: 0;
            }
        }
        .filter-bottom--right {
            .btn {
                margin-left: 10px;
                margin-right: 0;
                max-height: 18px;
                svg {
                    margin-top: -4px;
                }
            }
        }
        &--selection {
            display: none;
            &-list {
                display: none;
                margin-top: 40px;
                &.show {
                    display: block;
                }
            }
            &-item {
                line-height: 29px;
                margin: 8px 0;
                &.initial {
                    &:first-of-type {
                        button {
                            color: $stone-black;
                        }
                    }
                }
                .active {
                    color: $stone-black;
                }
            }
            .btn {
                padding-top: 10px;
            }
        }
        // very bizarre edge case where we need this layout on desktop
        @media screen and (min-width: $break-desktop-min) {
            .filter-top.filter-border-bottom {
                padding: 0;
            }
            &--selection-list {
                margin: 25px;
                margin-top: 50px;
            }
        }
    }
    &-links {
        display: flex;
        align-items: center;
        li {
            margin-right: 10px;
        }
        &--underline {
            border-bottom: 1px solid transparent;
            transition: color 0.4s ease-in-out;
            &.active, &:hover {
                padding-bottom: 10px;
                border-color: $stone-black;
            }
        }
    }
    &-sort {
        display: flex;
        align-items: center;
        cursor: pointer;
        &--text, &--selected {
            transition: color 0.4s ease-in-out;
            margin-left: 5px;
        }
        &:hover {
            button {
                color: $stone-black;
            }
        }
    }
    &.sticky {
        position: absolute;
        top: 0;
    }
    &-links {
        a {
            color: $concrete-grey;
            text-decoration: none;
            &:hover, &.active {
                color: $stone-black;
            }
        }
    }
    &-modal {
        position: relative;
        &--content {
            max-height: 0;
            overflow-y: scroll;
            &.no-scrollbar {
                // minify scrollbars for every browser
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none;  /* IE 10+ */
                &::-webkit-scrollbar { /* WebKit */
                    width: 0;
                    height: 0;
                }
            }
            .filter-topper {
                opacity: 0;
                pointer-events: none;
            }
            &.show {
                display: block;
                padding: 15px 0;
                //padding-bottom: 15px;
                max-height: 500px;
                &:has(:focus-visible) {
                    li {
                        padding-left: 1px;
                    }
                }
                .filter-topper {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
        &--date {
            flex-wrap: wrap;
        }
        &--year {
            input {
                width: calc(100% - 10px);
                margin: 2px 0 10px 0;
            }
        }
        &--list {
            &-item {
                margin: 0 0 3px;
            }
            .adv-search-item {
                color: $stone-black;
                text-align: left;
                text-decoration: underline;
                text-decoration-color: transparent;
                text-underline-offset: 4px;
                text-decoration-thickness: 1px;
            }
            &:hover {
                .adv-search-item {
                    color: $concrete-grey;
                    &:hover {
                        color: $stone-black;
                        text-decoration-color: currentColor;
                    }
                }
            }
        }
        @media screen and (max-width: $break-tablet-max) {
            &--content {
                &.show {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: calc(100vh - 20px);
                    max-height: 100vh;
                    z-index: 10;
                    background-color: white;
                }
            }
            .btn-filter-close {
                position: fixed;
                background-color: white;
                padding: 20px;
                top: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                }
            }
            &--long {
                &--content {
                    margin-top: 60px;
                    
                }
                &--row {
                    border: none;
                    padding: 0 20px;
                    font-size: 24px;
                    line-height: 30px;
                    letter-spacing: -0.8px;
                }
                &--title {
                    margin: 15px 0;
                    padding-bottom: 5px;
                    border-bottom: 1px solid $clay-grey;
                }
                .filter-modal--list {
                    padding: 0;
                    margin-top: 0;
                }
            }
            &--list {
                padding: 0 20px;
                margin-top: 60px;
                margin-bottom: 80px;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -.8px;
            }
            &--year {
                margin-top: calc(100vh - 580px);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 30px;
                input {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.25px;
                    margin-bottom: 5px;
                    &::placeholder {
                        color: $concrete-grey;
                    }
                }
                label {
                    opacity: 0;
                }
                .input-group {
                    width: calc(50% - 10px);
                }
                .btn {
                    position: absolute;
                    bottom: 0;
                    right: 20px;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -.5px;
                }
            }
        }
    }
    &-overflow-x {
        
        //transform: rotate(180deg);
        overflow-y: hidden;
        overflow-x: auto;
            // hide scrollbars
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        &::-webkit-scrollbar { /* WebKit */
            width: 0;
            height: 0;
        }
        padding: 17px 0;
        
        &.filter-top--left {
            margin-bottom: 0;
        }
        &-wrapper {
            position: relative;
            //max-width:79%;

        }
        &::before {
            content: '';
        }
        &.right::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            pointer-events: none;
            height: 100%;
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);

        }
        &.left::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            pointer-events: none;
            height: 100%;
            background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);

        }
        &-button {
            visibility: hidden;
            position: absolute;
            top: 31%;
            right: 0;
            width: 16px;
            border: 0;
            z-index: 20;
            svg path {
                transition: all 0.3s ease-in-out;
            }

            &.reversed {
                transform: rotate(180deg);
                left: 5px;
            }
            &:hover {
                svg path {
                    fill: $stone-black;
                }
            }

        }
    }
    @media screen and (min-width: $break-tablet-min) {
        &-theme {
            display: flex;
            &--name {
                width: calc((100% - 60px)/ 4);
                margin-right: 20px;
            }
            &--detail {
                width: 75%;
                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &.sticky {
            top: 80px;
            position: fixed;
            z-index: 5;
            background-color: $salt-white;
        }
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.4px;
        &-top {
            min-height: 57px;
            margin-bottom: 0;
            
            &--left {
                margin-bottom: 1px;
            }
            &--right {
                .filter-sort--text {
                    margin-left: 0;
                }
                .btn:not(.btn-lightbox-form, .calendar-arrow) {
                    margin-left: 5px;
                }
            }
        }
        &-bottom {
            min-height: 57px;
            padding-bottom: 0;
            // &--left {
            //     .dropdown {
            //         padding: 0;
            //     }
            // }
            //padding-bottom: 0;
        }
        &-collection {
            //padding: 20px 0;
            .filter-top {
                margin-bottom: 0;
            }
            .filter-bottom--left {
                .filter-list {
                    &.modal-open {
                        .filter-btn {
                            color: $concrete-grey;
                            &.active, &:hover {
                                color: $stone-black;
                            }
                        }
                    }
                }
                .filter-btn {
                    padding: 15px 0 20px 0;
                    border-top: 2px solid transparent;
                    &.active, &:hover {
                        border-color: $stone-black;
                    }
                }
            }
            .filter-bottom--right .dropdown {
                //padding: 15px 0 0 0;
            }
        }
        &-user {
            margin-bottom: 30px;
        }
        &-list {
            display: flex;
            li {
                margin-right: 14px;
            }
        }
        &-criteria {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
            margin: 0 0 30px 0;
            align-items: flex-start;
            &-count {
                margin-right: 10px;
            }
            &--list {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }
            &--clear {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.75px;
                margin-top: 0;
            }
            li {
                // margin-left: 10px;
                margin-left: 0;
                margin-right: 12px;
                svg {
                    width: 11px;
                    height: 11px;
                    margin-bottom: -2px;
                }
            }
        }
        &-theme {
            padding-top: 20px;
            margin-bottom: 65px;
            &--name {
                font-size: 26px;
                line-height: 32px;
                letter-spacing: -0.75px;
                width: calc((100% - 180px)/ 4);
                margin-right: 60px;
            }
            &--detail {
                font-size: 21px;
                line-height: 27px;
                p {
                    font-size: 21px;
                    line-height: 27px;
                }
            }
        }
        &-exhibition {
            .filter-top--left {
                width: auto;
            }
        }
        &-links {
            li {
                margin-right: 15px;
                white-space: nowrap;
            }
            &--underline {
                border-bottom: 2px solid transparent;
                &.active, &:hover {
                    padding-bottom: 15px;
                }
            }
        }
        &-accordion {
            .filter-top--right {
                button {
                    max-height: 20px;
                }
                .btn:not(.btn-lightbox-form) {
                    margin-left: 20px;
                    white-space: nowrap;
                }
            }
        }
        &-modal {
            &::after {
                content: '';
                width: 100vw;
                height : 10%;
                max-height: 30px;
                box-shadow: 0 14px 9px -9px $clay-grey;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -50vw;
            }
            &--content {
                transition: all 0.6s ease-in-out;
                &.show {
                    display: flex;
                }
                .filter-topper {
                    transition: opacity 0.6s ease-in-out;
                }
            }
            &--list {
                width: calc(100% - 340px);
                &-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding-bottom: 15px;
                }
                &-item {
                    width: calc(50% - 10px);
                    font-size: 22px;
                    line-height: 32px;
                    letter-spacing: -0.75px;
                    display: flex;
                    padding-bottom: 7px;
                }
            }
            &--image {
                width: 340px;
                height: 100%;
                min-height: 340px;
                max-height: 340px;
                padding-bottom: 10px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                pointer-events: none;
                position: sticky;
                top: 0;
                img {
                    max-width: 100%;
                    max-height: 350px;
                    object-fit: contain;
                }
            }
            &--shortcut {
                display: flex;
                position: absolute;
                top: -40px;
                right: 0;
                background-color: white;
                
                ul {
                    display: flex;
                }
                li {
                    margin-left: 3px;
                }
                &:hover {
                    .btn-shortcut {
                        color: $clay-grey;
                        &:hover {
                            color: $stone-black;
                            text-decoration: underline;
                        }
                    }
                }
            }
            &--long {
                .filter-modal--image {
                    position: absolute;
                    top: 70px;
                    left: 0;
                    img {
                        max-width: calc(100% - 40px);
                    }
                }
                &--content {
                    width: 100%;
                }
                &--row {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 15px 0 40px 0;
                    &:first-of-type {
                        border-top: none;
                        padding-top: 0;
                    }
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                }
            }
            &--date {
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                .filter-modal--list {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    .list-column {
                        margin-top: 0;
                        width: calc(50% - 22.5px);
                    }
                    &-item {
                        width: 100%;
                    }
                }
            }
            &--year {
                position: relative;
                margin: 35px 0;
                .btn {
                    position: absolute;
                    right: -65px;
                    bottom: 0;
                    padding-bottom: 10px;
                    font-size: 22px;
                    line-height: 32px;
                    letter-spacing: -0.75px;
                    border-bottom: 1px solid $clay-grey;
                    &:hover {
                        color: $clay-grey;
                    }
                }
            }
        }
    }
}