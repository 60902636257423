.modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease, display 0.6s ease allow-discrete;
    &.open {
        display: block;
        opacity: 1;
        pointer-events: auto;
    }
    section {
        background-color: black;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        min-width: 320px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        .social {
            width: 100vw;
            margin: 0;
            &-wrapper {
                width: 100vw;
                height: 100vh;
                display: none;
                align-items: center;
                justify-content: center;
                &.show {
                    display: flex;
                }
            }
            &-title {
                font-size: 2rem;
                color: #fff;
                font-weight: 600;
                text-align: center;
                margin-bottom: 20px;
                line-height: normal;
                letter-spacing: normal;
            }
            .close {
                position: absolute;
                top: 30px;
                right: 30px;
                visibility: hidden;
            }
            ul {
                overflow: hidden;
                font-size: 0;
                text-align: center;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .accessible-link {
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    overflow: hidden;
                    width: 120px;
                    height: 120px;
                    margin: 20px;
                    -webkit-border-radius: 120px;
                    -moz-border-radius: 120px;
                    -ms-border-radius: 120px;
                    border-radius: 120px;
                    -webkit-transform: scale(1, 1);
                    -moz-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    transform: scale(1, 1);
                    -webkit-transition: transform 0.2s ease;
                    -moz-transition: transform 0.2s ease;
                    -ms-transition: transform 0.2s ease;
                    transition: transform 0.2s ease;
                    cursor: pointer;
                    &:focus, &:hover {
                        -webkit-transform: scale(1.2, 1.2);
                        -moz-transform: scale(1.2, 1.2);
                        -ms-transform: scale(1.2, 1.2);
                        transform: scale(1.2, 1.2);
                    }
                }
                .addthis_button_copy_link {
                    background-color: $washington-red;
                    &.copied {
                        background-color: $stone-black;
                    }
                }
                .social-facebook {
                    background-color: #3b579d;
                }
                .social-twitter {
                    background-color: #57a8df;
                }
                .social-x {
                    background-color: rgba(15,20,25,1.00);
                }
                // .social-pinterest {
                //     background-color: #ca1f26;
                // }
            }
        }
    }
}

@starting-style {
    .modal {
        opacity: 0;
    }
    .modal.open {
        opacity: 1;
    }
}