.list {
    &-module {
        &--top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $clay-grey;
            padding-bottom: 10px;
            margin-bottom: 10px;
            position: relative;
            a {
                // text-decoration: none;
            }
            &+.list-row--block {
                .list-row--item:first-of-type {
                    border: none;
                }
            }
            .list-module--title {
                &+.filter-mobile {
                    &.open {
                        .filter-list-module--sort {
                            span {
                                display: none;
                            }
                        }
                    }
                    &:not(.open) {
                        width: auto;
                        .filter-list-module--sort {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            button {
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        &--title {
            @extend .typography-4;
            @media screen and (max-width: $break-tablet-max) {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.25px;
            }
        }
        &--body {
            &:not(.masonry) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
            }
            &.list-module--center {
                align-items: center;
            }
            .list-item {
                margin-bottom: 35px;
            }
        }
        &--col-2 {
            .list-item {
                .no-image {
                    width: calc(100vw - 40px);
                }
                &--title {
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.75px;
                }
                &--text {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.25px;
                    margin-top: 6px;
                }
            }
        }
        &--col-3 {
            .list-item {
                width: calc((100% - 20px) / 2);
                .no-image {
                    width: calc(50vw - 30px);
                }
            }
        }
        &--col-4 {
            .list-item {
                width: calc((100% - 20px) / 2);
                .no-image {
                    width: calc(50vw - 30px);
                }
            }
        }
        &--sort {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            display: flex;
            align-items: center;
        }
        .sort-list {
            top: 90%;
        }
        .main-bodytext--chapter {
            margin-bottom: 20px;
        }
    }
    &-grid {
        .list-module--col-4 {
            display: grid;
            grid-column-gap: 20px;
            grid-template-columns: 1fr 1fr;
            .list-item {
                width: 100%;
            }
        }
    }
    &-collection {
        .list-item--loadmore {
            //justify-content: flex-end;
            &-wrapper {
                height: auto;
                display: flex;
                margin-bottom: 20px;
            }
        }
    }
    &-calendar {
        .list-item {
            display: flex;
            align-items: flex-start;
            padding: 20px 0;
            overflow: hidden;
            border-top: 1px solid $clay-grey;
            margin-bottom: 0;
            &:first-of-type {
                border-top: none;
                padding-top: 10px;
            }
            &--image {
                width: 130px;
                margin-right: 20px;
            }
            &--content {
                margin-top: 0;
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 150px);
                &-left, &-right {
                    width: 100%;
                }
                &-right {
                    display: flex;
                    align-items: flex-end;
                }
            }
            &.no-image-invisible {
                .no-image {
                    visibility: hidden;
                    width: 0;
                    margin: 0;
                }
                .list-item--content {
                    width: 100%;
                }
            }
            @media screen and (max-width: $break-tablet-max) {
                &--title {
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: -0.5px;
                }
            }
        }
        @media screen and (max-width: $break-tablet-max) {
            &+.carousel {
                margin-top: 60px;
            }
        }
    }
    &-item {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            .lightbox-image--wrapper {
                opacity: 1;
                background-color: rgba($color: $stone-black, $alpha: 0.3);
            }
        }
        &--none {
            display: none;
        }
        &--wrapper {
            display: table;
            position: relative;
            &.no-image {
                width: 100%;
                .bg--rectangle-grey {
                    width: 100%;
                    max-height: 300px;
                }
            }
            &:has(a:focus-visible) {
                overflow: visible;
            }
        }
        &--link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            color: transparent;
        }
        &:has(a:focus-visible) {
            overflow: visible;
        }
        img {
            width: 100%;
            max-height: 320px;
            object-fit: contain;
            @media screen and (min-width: 450px) and (max-width: $break-tablet-max) {
                // max-height: none;
                max-height: 500px;
            }
        }
        &--image {
            position: relative;
            img {
                display: block;
            }
            &.no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30vw;
                max-height: 200px;
                &-invisible {
                    visibility: hidden;
                    height: 0;
                }
            }
        }
        &--icon {
            position: absolute;
            left: 5px;
            bottom: 5px;
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 50%;
            background-color: white;
            &.hide {
                display: none;
            }
        }
        &--cover {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .4s ease;
            background-color: rgba($color: $stone-black, $alpha: 0.3);
            &:has(button:focus) {
                opacity: 1;
                .fav-icon {
                    display: none;
                }
                .fav-add {
                    .icon-heart {
                        display: block;
                    }
                    &:hover {
                        .icon-heart {
                            display: none;
                        }
                        .icon-check {
                            display: block;
                        }
                    }
                }
                .fav-remove {
                    .icon-check {
                        display: block;
                    }
                    &:hover {
                        .icon-check {
                            display: none;
                        }
                        .icon-x {
                            display: block;
                        }
                    }
                }
            }
            .list-item--icon {
                z-index: 1;
            }
        }
        // every odd child after the gutter sizer
        @media screen and (max-width: $break-tablet-min) {
            .tooltip-top:before {
                left: 0;
            }
        }
        
        &--content {
            margin-top: 8px;
            display: table-caption;
            caption-side: bottom;
            position: relative;
            &--type {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            &--resource {
                position: relative;
                z-index: 1;
                // text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 5px;
                span {
                    white-space: nowrap;
                }
                svg {
                    margin-left: 5px;
                    path {
                        fill: $concrete-grey;
                    }
                }
                &:hover {
                    color: $stone-black;
                    span {
                        text-decoration: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 3px;
                        
                    }
                    svg path{
                        fill: $stone-black;
                    }
                }
            }
        }
        &--type {
            font-size: 10px;
            line-height: 16px;
            letter-spacing: .5px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
        }
        &--condition {
            font-size: 9px;
            line-height: 16px;
            letter-spacing: .5px;
        }
        &--title {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            padding-bottom: 2px;
            width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
            text-decoration: underline;
            text-decoration-color: #fff;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;

        }
        &--text {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.25px;
            margin-top: 4px;  
        }
        &--date, &--location {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            margin-top: 4px;
            width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &-guide {
            &--title {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.25px;
                margin-top: 4px;
            }
            &--text {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.25px;
                margin-top: 8px;
            }
            .list-item--content {
                width: 100%;
            }
        }
        &--loadmore {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
            > span {
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.75px;
            }
            svg {
                margin-left: 10px;
                path {
                    fill: $concrete-grey;
                }
            }
            &:hover {
                span {
                    color: $stone-black;
                }
                svg path {
                    fill: $stone-black;
                }
            }
        }
        &:not(.list-item-guide):hover {
            .list-item--cover {
                opacity: 1;
            }
            .list-item--title:not(.list-item--artist), .list-row--item--title {
                text-decoration-color: currentColor;
            }
            .list-item--date, .list-row--item--date {
                color: $stone-black;
            }
            .concrete-grey {
                color: $stone-black;
            }
        }
    }
    &-row {
        &--block {
            margin-bottom: 35px;
            &--title {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: -0.1px;
            }
        }
        &--item {
            display: block;
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid $clay-grey;
            &:first-of-type {
                border-top: 1px solid $stone-black;
            }
            &--text {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.25px;
            }
        }
    }
    &-publications {
        .list-item {
            display: block;
            &--image {
                height: 30vw;
                max-height: 260px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                &.no-image {
                    width: calc(50vw - 50px);
                }
                img {
                    width: auto;
                    height: auto;
                    max-width: calc(100% - 10px);
                    max-height: calc(100% - 10px);
                    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.25);
                }
            }
            &--content {
                display: block;
                word-break: break-word;
            }
        }
        .list-module--sort {
            .btn {
                margin-left: 10px;
                margin-right: 0;
            }
        }
        .filter-mobile {
            &.open {
                .list-module--sort {
                    display: none;
                }
            }
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        &-module{
            &--col-2 {
                .list-item {
                    width: calc((100% - 60px) / 2);
                    .no-image {
                        width: calc(50vw - 70px);
                        max-width: 690px;
                    }
                }
            }
            &--body {
                &:not(.masonry) {
                    width: 100%;
                    &.list-module--col-3 {
                        display: grid;
                        grid-column-gap: 40px;
                        grid-template-columns: 1fr 1fr 1fr;
                        .list-item {
                            width: 100%;
                        }
                    }
                    &.list-module--col-4 {
                        display: grid;
                        grid-column-gap: 40px;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        .list-item {
                            width: 100%;
                        }
                    }
                }
            }
            &--col-3 {
                .list-item {
                    .no-image {
                        width: calc((100vw - 140px)/3);
                        max-width: 417.3333333333333px;
                    }
                    &--image {
                        &.no-image {
                            width: calc((100vw - 140px)/3);
                        }
                    }
                }
                &.masonry {
                    .list-item {
                        width: calc((100% - 100px) / 3);
                        &--image.no-image {
                            width: calc((100vw - 100px) / 3);
                        }
                    }
                }
            }
            &--col-4 {
                .list-item {
                    width: calc((100% - 120px) / 4);
                    &--image {
                        &.no-image {
                            width: calc(25vw - 45px);
                        }
                    }
                }
                &.masonry {
                    .list-item--image.no-image {
                        width: calc(25vw - 30px);
                    }
                }
            }
        }
        &-collection {
            .list-item--loadmore {
                &-wrapper {
                    margin-bottom: 50px;
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &-module {
            &--top {
                padding-bottom: 20px;
                margin-bottom: 20px;
                padding-top: 20px;
            }
            &--body {
                .list-item {
                    margin-bottom: 45px;
                }
                &:not(.masonry) {
                    &.list-module--col-3 .list-item {
                        width: 100%;
                        .no-image {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
            &--col-2 {
                .list-item {
                    &--title {
                        font-size: 26px;
                        line-height: 32px;
                    }
                    &--text {
                        font-size: 18px;
                        line-height: 24px;
                        margin-top: 8px;
                    }
                }
            }
            &--col-3 {
                // &:not(.masonry) {
                //     .list-item {
                //         width: 100%;
                //     }
                // }
                .list-item {
                    width: calc((100% - 200px) / 3);
                    &--title {
                        margin-top: 6px;
                    }
                    &--text {
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.25px;
                        margin-top: 8px;
                    }
                    // .list-item--image.no-image {
                    //     width: calc((100vw - 200px) / 3);
                    // }
                }
            }
            &--col-4 {
                .list-item {
                    width: calc((100% - 120px) / 4);
                    &--image {
                        &.no-image {
                            width: calc(25vw - 65px);
                        }
                    }
                }
                &.masonry {
                    .list-item--image.no-image {
                        width: calc(25vw - 65px);
                        max-width: 315px;
                    }
                }
            }
            &--sort {
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.4px;
                

            }
        }
        &-calendar {
            .list-item {
                padding: 30px 0;
                margin-bottom: 0;
                &--image {
                    width: 310px;
                    margin-right: 40px;
                }
                &--content {
                    margin-top: 0;
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% - 350px);
                }
            }
        }
        &-item {
            &--link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            &--image {
                position: relative;
                img {
                    width: 100%;
                }
            }
            img {
                max-height: 700px;
            }
            &--icon {
                left: 12px;
                bottom: 12px;
            }
            &--content {
                margin-top: 15px;
                &--resource {
                    margin-left: 10px;
                    svg {
                        margin-left: 10px;
                    }
                }
            }
            &--type {
                font-size: 14px;
                line-height: 20px;
            }
            &--condition {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: .75px;
            }
            &--title {
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.5px;
                margin-top: 4px;
            }
            &--date, &--location {
                font-size: 21px;
                line-height: 25px;
                letter-spacing: -0.5px;
            }
            
            &-guide {
                &--title {
                    font-size: 26px;
                    line-height: 32px;
                    letter-spacing: -0.75px;
                }
                &--text {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                }
            }
            &--loadmore {
                > span {
                    font-size: 26px;
                    line-height: 32px;
                }
            }
        }
        &-row {
            &--block {
                margin-bottom: 40px;
                &--title {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                }
            }
            &--item {
                display: flex;
                padding-top: 15px;
                margin-top: 15px;
                align-items: start;
                &--text {
                    font-size: 23px;
                    line-height: 32px;
                }
                &--smallcol {
                    width: 25%;
                    min-width: 315px;
                    padding-right: 20px;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                    text-decoration-color: transparent;
                }
                &--largecol {
                    width: 75%;
                }
            }
        }
        &-publications {
            .list-item {
                &--image {
                    // width: 100%;
                    height: 30vw;
                    max-height: 260px;
                    &.no-image {
                        width: calc(25vw - 70px);
                    }
                    img {
                        max-width: calc(100% - 25px);
                        max-height: calc(100% - 25px);
                        object-fit: contain;
                    }
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-mid) {
        &-calendar {
            .list-item {
                &--content {
                    position: unset;
                    justify-content: space-between;
                    &-left {
                        width: 55%;
                    }
                    &-right {
                        width: 40%;
                        align-items: flex-start;
                        justify-content: flex-end;
                    }
                }
                &--title {
                    padding-bottom: 50px;
                }
                &:not(.no-image-invisible) {
                    .list-item--location {
                        position: absolute;
                        bottom: 30px;
                        left: 350px;
                    }
                }
                &--date.align-right {
                    text-align: end;
                }
            }
        }
        &-module--col-3 {
            .list-item--image.no-image {
                width: calc(1360px / 3);
            }
        }
        &-module--col-4 {
            .list-item--image.no-image {
                max-width: 330px;
            }
        }
        &-publications {
            .list-item--image.no-image {
                max-width: 310px;
            }
        }
    }
}

.gallery {
    .list-module--col-2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        row-gap: 60px;
    }
    .list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        &:hover {
            .gallery-caption {
                visibility: visible;
            }
        }
    }
    &-caption {
        visibility: hidden;
    }
    img {
        width: auto;
        max-width: 100%;
        object-fit: contain;
        display: block;
    }
    &-caption {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.25px;
        color: $concrete-grey;
        margin-top: 10px;
    }
    @media screen and (min-width: $break-tablet-min) {
        .list-module--col-2 {
            row-gap: 70px;
            column-gap: 60px;
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &-caption {
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
        }
    }
}

.masonry {
    &.list-module--col-2 {
        .gutter-sizer {
            width: 0;
        }
        @media screen and (max-width: $break-mobile-max) {
            .list-item {
                visibility: visible;
            }
        }
    }
    &.list-module--col-3, &.list-module--col-4 {
        .gutter-sizer {
            width: 20px;
        }
    }
    .gallery-caption {
        visibility: hidden;
    }
    img {
        display: block;
    }
    .list-item {
        visibility: hidden;
        &.show {
            visibility: visible;
        }
        &:hover {
            .gallery-caption {
                visibility: visible;
            }
            .list-item--cover {
                opacity: 0;
            }
            .list-item--title:not(.list-item--artist), .list-row--item--title {
                // text-decoration: none;
            }
            .list-item--date, .list-row--item--date {
                color: $concrete-grey;
            }
            .concrete-grey {
                color: $concrete-grey;
            }
        }
        &--wrapper {
            &:hover {
                .list-item--cover {
                    opacity: 1;
                }
                .list-item--title:not(.list-item--artist), .list-row--item--title {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
                .list-item--date, .list-row--item--date {
                    color: $stone-black;
                }
                .concrete-grey {
                    color: $stone-black;
                }
            }
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        .list-item {
            margin-bottom: 50px;
        }
        &.list-module--col-2 {
            .gutter-sizer {
                width: 60px;
            }
        }
        &.list-module--col-3 {
            .gutter-sizer {
                width: 50px;
            }
            
        }
        &.list-module--col-4 {
            .list-item {
                width: calc((100% - 60px)/ 4);
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        .list-item {
            margin-bottom: 60px;
        }
        &.list-module--col-3 {
            .gutter-sizer {
                width: 100px;
            }
            .list-item {
                width: calc((100% - 200px) / 3);
                max-width: 386.666666666666px;
            }
        }
        &.list-module--col-4 {
            .list-item {
                width: calc((100% - 180px)/ 4);
                margin-bottom: 50px;
            }
            .gutter-sizer {
                width: 60px;
            }
        }
    }
}