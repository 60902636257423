.footer {
    margin-top: 50px;
    &-content {
        &--top {
            display: flex;
            padding: 30px 0;
            justify-content: space-between;
        }
        &--title {
            font-size: 9px;
            line-height: 16px;
            letter-spacing: 0.75px;
        }
        &--text {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.25px;
            margin-bottom: 2px;
        }
        &--right {
            width: calc(50% - 15px);
            .footer-content--column {
                &:first-of-type {
                    margin-bottom: 14px;
                }
            }
        }
        &--bottom {
            display: flex;
            padding: 10px 0;
            justify-content: space-between;
            border-top: 1px solid $clay-grey;
        }
        &--links {
            width: calc(50% - 15px);
            li {
                &:not(.footer-content--title) {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                }
                a {
                    text-underline-offset: 4px;
                    // text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    text-decoration-thickness: 1px;
                        
                        
                    }
                }
            }
        }
    }
    &-map {
        width: 155px;
        margin-right: 25px;
    }
    &-social {
        display: flex;
        padding: 15px 0;
        border-top: 1px solid $clay-grey;
        &--links {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        li {
            margin-right: 25px;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    &-custom-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-top: 1px solid $clay-grey;
        .btn {
            padding: 12px 0;
            width: calc(50% - 15px);
            font-size: 11px;
            line-height: 16px;
            text-decoration: none;
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-top: 1px solid $clay-grey;
        .copyright-info {
            font-size: 10px;
            line-height: 20px;
        }
        &--links {
            display: none;
        }
    }
    svg path {
        fill: $salt-white;
    } 
    @media screen and (max-width: $break-mobile-max) {
        .no-mobile {
            display: none;
        }  
    }
    @media screen and (min-width: $break-tablet-min) {
        &-content {
            &--top {
                display: flex;
                padding: 30px 0;
            }
            &--title {
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 5px;
            }
            &--text {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.25px;
            }
            &--right {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 160px);
                .footer-content--column {
                    width: calc(50% - 15px);
                    &:first-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            &--bottom {
                padding: 0 0 30px 0;
                border-top: none;
                margin-left: 160px;
            }
            &--links {
                width: calc(50% - 15px);
                li:not(.footer-content--title) {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                }
            }
        }
        &-map {
            width: 130px;
            margin-right: 30px;
        }
        &-custom-links {
            justify-content: flex-start;
            .btn {
                width: auto;
                padding: 10px 20px;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.75px;
                margin-right: 18px;
            }
        }
        &-bottom {
            .copyright-info {
                font-size: 12px;
                letter-spacing: 0.75px;
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        margin-top: 75px;
        &-top {
            position: relative;
        }
        &-content {
            display: flex;
            padding: 40px 0 130px;
            &--top {
                padding: 0;
                width: calc(45% + 145px);
            }
            &--right {
                display: flex;
            }
            &--bottom {
                padding: 0;
                margin-left: 0;
                width: calc(55% - 175px);
            }
        }
        &-social {
            border-top: none;
            padding: 0;
            width: 30px;
            position: absolute;
            top: 40px;
            right: 0;
            &--links {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            li {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }
        &-custom-links {
            border-top: none;
        }
        &-bottom {
            justify-content: flex-start;
            &--links {
                display: flex;
                width: calc(77.5% - 179.5px);
                li {
                    &:first-of-type {
                        // width: 34%;
                        width: 50%;
                        margin-left: 10px;
                    }
                }
                a {
                    // text-decoration: none;
                    text-underline-offset: 4px;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-thickness: 1px;
                        
                    }
                }
            }
            .copyright-info {
                width: calc(22.5% + 152.5px);
            }
        }
    }
    // @media screen and (min-width: $break-desktop-mid) {
    //     &-bottom {
    //         &--links {
    //             li {
    //                 &:first-of-type {
    //                     width: calc(33% - 10px);
    //                 }
    //             }
    //         }
    //     }
    // }
}