body {
    overflow-x: hidden;
    background-color: white;
    &.no-focus-outline {
        *:focus {
            outline: none;
        }
    }
    &.fixed {
        height: 100vh;
        overflow: hidden;
        .filter--wrapper {
            z-index: 1;
        }
        @media screen and (min-width: $break-desktop-min) {
            height: auto;
            overflow: auto;
            
        }
    }
}

main {
    max-width: 1440px;
    margin: 0 auto;
}

#main {
    min-height: 700px;
    > .wrap:first-of-type {
        min-height: 700px;
    }
}

.wrap {
    margin: 0 20px;
    @media screen and (min-width: $break-tablet-min) {
        margin: 0 30px;
    }
    @media screen and (min-width: $break-desktop-min) {
        margin: 0 40px;
    }
    @media screen and (min-width: $break-desktop-max) {
        margin: 0;
    }
}

footer >.wrap {
    max-width: 1440px;
    @media screen and (min-width: $break-desktop-max) {
        margin: 0 auto;
    }
}

.no-desktop {
    @media screen and (min-width: $break-desktop-min) {
        display: none;
    }
}
.desktop-only {
    display: none;
    @media screen and (min-width: $break-desktop-min) {
        display: block;
        &--flex {
            display: flex;
        }
    }
}

.skip-to-main-content-link {
    position: absolute;
    top: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: white;
    opacity: 0;
    width: 100vw;
    text-align: center;
    a {
        color: $stone-black;
        &:focus {
            outline: -webkit-focus-ring-color auto 1px;
        }
    }
    &:has(a:focus) {
        top: 0;
        opacity: 1;
    }
}