.record {
    &-description {
        .show-less {
            display: -webkit-box;
            max-width: 930px;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .truncation {
            &-icon {
                margin-right: 3px;
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &-description {
            .show-less {
                display: -webkit-box;
                max-width: 930px;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .truncation {
                &-icon {
                    margin-right: 3px;
                }
            }
        }
    }
}

.tooltip {
    &::before {
        display: none;
        position: absolute;
        padding: 3px 10px;
        color: $salt-white;
        content: attr(title);
        white-space: nowrap;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: .75px;
        text-transform: uppercase;
        background-color: $concrete-grey;

    }
    &:after {
        display: none;
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        transform: rotate(45deg);
        background-color: $concrete-grey;
    }
    &-top {
        &::before {
            top: -30px;
        }
        &:after {
            top: -10px;
        }
    }
    &-left {
        &:before {
            top:7px;
            left: 36px;
            z-index: 5;
        }
        &:after {
            top:14px;
            left:32px;
        }

    }
    &-right {
        &:before {
            top:7px;
            right: 36px;
            z-index: 5;
        }
        &:after {
            top:14px;
            right:32px;
        }
    }
    &-bottom {
        &::before {
            transform: translate(-50%,50%);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    &-position--left {
        &:before {
            left: -30px;
        }
    }
    &-position--right {
        &:before {
            right: -10px;
        }
    }
    &:not(.no-tooltip):hover {
        &::before {
            display: block;
        }
        &:after {
            display: block;
        }
    }
}
.filter--wrapper.fixed {
    .tooltip-top {
        &:before {
            top: calc(100% + 10px);
            z-index: 1;
        }
        &:after {
            top: calc(100% + 5px);
        }
    }
}

.theme-description {
    color: $stone-black;
    &--title {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.75px;
        margin: 14px 0;
    }
    &--content {
        max-width: 1011px;
        p {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.25px;
            margin-bottom: 15px;
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &--left {
            margin-right: 20px;
        }
        &--title {
            font-size: 26px;
            line-height: 32px;
            margin-top: 13px;
            margin-bottom: 0;
        }
        &--content {
            width: 75%;
            margin-top: 22px;
            p {
                font-size: 21px;
                line-height: 27px;
                letter-spacing: -0.25px;
                margin-bottom: 25px;
            }
        }
    }
}

.play-wrapper {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .play-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: rgba($color: $stone-black, $alpha: 0.1);
        &:hover {
            background-color: rgba($color: $stone-black, $alpha: 0.3);
        }
        // svg {
        //     border-radius: 50%;
        //     background-color: rgba(27,27,27,.3);
        // }
    }
    video {
        display: none;
    }
    &.play {
        img {
            display: none;
        }
        .play-cover {
            display: none;
        }
        video {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.footnote {
    margin-bottom: 30px;
    ul {
        padding: 0;
        list-style: none;
    }
    &-list {
        .ref-link {
            position: relative;
            margin-right: 10px;
            &:after {
                content: '.';
                position: absolute;
                margin-left: 3px;
            }
        }
    }
}