.nav {
    &_menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        color: $salt-white;
        &.show {
            //overflow-y: auto;
        }
        &--tab {
            display: none;
            &.active {
                display: block;
            }
        }
        &-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $stone-black;
            z-index: 10;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease-in-out;
            &.open {
                opacity: 0.6;
                pointer-events: auto;
            }
        }
        p {
            opacity: 0.8;
            color: $salt-white;
            @extend .typography-8;
        }
        .login-menu {
            &-tab {
                color: $salt-white;
                @extend .typography-6;
                margin-right: 20px;
                opacity: 0.5;
                // text-decoration: none;
                text-underline-offset: 5px;
                &.active,
                &:hover {
                    opacity: 1;
                    color: $salt-white;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
                &.deactive {
                    opacity: 1;
                    pointer-events: none;
                    &:hover {
                        // text-decoration: none;
                    }
                }
                &--wrapper {
                    margin-bottom: 20px;
                }
                &-form {
                    display: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    &.active {
                        display: block;
                    }
                    input {
                        border: 0;
                        border-bottom: 1px solid $rubber-grey;
                        width: 100%;
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus {
                            -webkit-box-shadow: 0 0 0 1000px $concrete-grey inset;
                            -webkit-text-fill-color: $salt-white;
                            border: 0;
                            border-bottom: 1px solid $salt-white;
                        }
                        &+.form-input--error {
                            color: $salt-white;
                            opacity: 1;
                        }
                    }
                    .form-error {
                        margin-top: 20px;
                        opacity: 1;
                        color: $salt-white;
                        width:100%;
                    }
                    .tab-form-submit-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        margin-top: 20px;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        @media screen and (max-width: $break-tablet-min) {
                            &.col {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }
                        .btn-submit {
                            &:hover {
                                text-decoration: underline;
                                text-decoration-thickness: 1px;
                                text-underline-offset: 4px;
                            }
                            @media screen and (max-width: $break-tablet-min) {
                                &--register {
                                    margin-top: 10px;
                                }
                            }
                        }
                        > div {
                            display: flex;
                            align-items: center;
                            input[type="checkbox"] {
                                border: 1px solid $salt-white;
                                width: 20px;
                                height: 20px;
                                background: $concrete-grey;
                                margin-right: 10px;
                            }
                        }
                        label {
                            display: block;
                            .terms>a.terms-link {
                                text-decoration: underline;
                                text-decoration-thickness: 1px;
                                text-underline-offset: 4px;
                            }
                            a {
                                color: $salt-white;
                                // text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                    text-decoration-thickness: 1px;
                                    text-underline-offset: 4px;
                                }
                            }
                        }
                        button {
                            color: $salt-white;
                            margin-left: auto;
                            text-underline-offset: 5px;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: -0.5px;
                            @extend .typography-4;
                        }
                    }
                }
            }
        }
        .close-dropdown {
            position: absolute;
            right: 0;
            padding-top: 4px;
        }
        &--no_children {
            margin-bottom: 9px;
            margin-left: 32px;
        }
        .menu-list-wrapper {
            display: flex;
            margin-bottom: 40px;
            flex-direction: column;
            max-height: calc(100vh - 173px);
            overflow-y: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            &:has(:focus-visible) {
                overflow: visible;
            }
            .scripted-ui {
                //overflow: auto;
                min-width: 25%;
                > li {
                    margin-bottom: 6px;
                    .dropdown-toggle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        order: 1;
                        &:hover {
                            svg path {
                                stroke: $footer-grey;
                            }
                        }
                    }
                    a {
                        color: $salt-white;
                        // text-decoration: none;
                        @extend .typography-4;
                        order: 2;
                        &.active, &:hover {
                            text-decoration: underline;
                            text-decoration-thickness: 1px;
                            text-underline-offset: 4px;
                        }
                        &:focus {
                            outline: #00f auto 1px;
                        }
                    }
                    ul {
                        margin: 6px 40px;
                        > li {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        .wrap {
            padding-top: 40px;
            position: relative;
            height: 100vh;
        }
        &-toggle--wrapper {
            display: flex;
            align-content: center;

            &.disabled {
                a, button {
                    opacity: 0.5;
                }
            }
        }
        form {
            button {
                //unstyled
                background: none;
                border: none;
                padding: 0;
                //margin: 0;
                cursor: pointer;
            }
            // label {
            //     display: none;
            // }
            input {
                background-color: transparent;
                color: $salt-white;
                border-radius: 0;
                //border: 0;
                display: flex;
                align-items: center;
                padding: 20px 0;
                width: 100%;
                @extend .typography-4;
                &::placeholder {
                    color: $salt-white;
                    @extend .typography-4;
                }
                &:focus {
                    outline: none;
                }
                &[type=checkbox]:focus {
                    outline: blue solid 1px;
                }
            }
        }
        &--search {
            position: absolute;
            bottom: 100px;
            display: flex;
            
            width: 100%;
            background-color: $concrete-grey;
            z-index: 5;
            input {
                border: 0;
                border-top: 1px solid $salt-white;
            }
            button {
                width: 30px;
                margin-right: 10px;
            }
        }
        @media screen and (min-width: $break-tablet-min) {
            &#login-menu .wrap {
                padding-bottom: 20px;
            }
            .wrap {
                height: auto;
                padding-bottom: 100px;
                .login-menu-tab-form {
                    position: static;
                    transform: none;
                    margin-top: 60px;
                }
            }
            .menu-list-wrapper {
                flex-direction: row;
                justify-content: space-between;
                .close-dropdown {
                    position: static;
                    display: flex;
                    height: 23px;
                }
                .scripted-ui {
                    margin-right: 10px;
                    width: calc((100% - 55px) / 3);
                }
            }
            &--search {
                bottom: 0;
            }
            &--no_children {
                margin-bottom: 13px;
            }
        }
    }
    &--wrapper {
        @extend main;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 12px;
        transition: margin 0.3s ease-in-out;
    }
    &--left {
        margin: 0;
        display: flex;
        flex-direction: column;
        // text-decoration: none;
        justify-content: center;
        width: 20%;
        h1 {
            @extend .page-title;
            cursor: pointer;
            margin: 0;
            color: $stone-black;
        }
        a {
            font-size: 0;
        }
        .bold {
            font-weight: 900;
        }
    }
    &--right {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        //gap: 8px;
        
        .dropdown-toggle {
            &:hover {
                &#login-icon, &#menu-icon {
                    svg {
                        path, ellipse {
                            stroke: $concrete-grey;
                        }
                    }
                }
                &#search-icon {
                    svg path {
                        fill: $concrete-grey;
                    }
                }
            }
        }
    }
    &-icons {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        //height: 27px;
        position: relative;
        top : -12px;
        // > a {
        //     position: relative;
        //     top: -2px;
        // }
        > button, > a {
            
            height: 14px;
            max-width: 24px;
            svg {
                transform: scale(0.78);
            }
           
              
        }
        .tooltip{
            //height: 100%;
            position: relative;
            top: 0px;
            &::before {
                white-space: normal;
                width: 250px;
                text-align: left;
                right: -148px;
                z-index: 20;
                text-transform: none;
                font-size: 15px;
                letter-spacing: 0;
                padding: 10px 15px 11px 15px;
            }
        }
        a:focus {
            outline: -webkit-focus-ring-color auto 1px;
        }
    }
    &--list {
        gap: 14px;
        li {
            overflow: hidden;
            overflow: clip;
            overflow-clip-margin: 5px;
            &:has(a:focus-visible), &:has(a:focus) {
                overflow: visible;
            }
            a {
                // text-decoration: none;
                text-underline-offset: 4px;
                &.active, &:hover {
                    
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    
                }
                // &:hover {
                //     color: $concrete-grey;
                // }
            }

        } 
        &:has(.nav--list-name:hover) {
            .nav--list-name:not(:hover) {
                color: $concrete-grey;
            }
        }
    }
    .user-name {
        color: $concrete-grey;
        // text-decoration: none;
        &:hover {
            color: $stone-black;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
        }
    }
    &_desktop {
        display: none;
    }
    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        .nav-icons {
            top: 11px;
        }
        .nav--wrapper {
            
            margin-top: 7px;
            margin-bottom: 7px;
        }
        .nav--right span {
            display: none;
        }

    }
    @media screen and (min-width: 1500px) {
        &_menu .wrap,
        .wrap {
            margin: 20px auto 0;
            max-width: 1440px;

        }
    }
    @media screen and (max-width: $break-tablet-max) {
        &--right>span {
            font-weight: 400;
            font-size: 11px;
            line-height: 18px;
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &.sticky, &.small_logo {
            .nav {
                &--wrapper {
                    margin-top: 14px;
                    margin-bottom: 15px;
    
                }
                &--left svg {
                    height: 49px;
                    width: 229px;
                }
                &--right {
                    margin-top: 8px;
                    > span {
                        display: block;
                    }
                    // svg {
                    //     height: auto;
                    //     width: auto;
                    // }
                }
                &--list {
                    li {
                        display: flex;
                        align-items: center;
                    }
                    &-name {
                        font-size: 21px;
                        line-height: 27px;
                        letter-spacing: -0.4px;
                        
                    }
                }
                &-icons {
                    top: 0;
                }
            }
        }
        &-icons {
            top: 0;
            gap: 16px;
            //height: 27px;
            > button, > a {
                max-width: 27px;
                height: auto;
                svg {
                    transform: scale(1);
                }

            }
            .tooltip {
                top: 0;
                &::before {
                    right: -220px;
                }
            }
        }
        &--wrapper.wrap {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        &--right {
            flex-direction: row;
            gap: 26px;
            align-items: flex-start;
            
        }
        &--left {
            .mlkam-logo {
                width: 100%;
            }
        }
        &_desktop {
            display: block;
            &.nav--list {
                display: flex;
            }
        }
        &--list-name {
            line-height: 27px;
        }
    }
    @media screen and (min-width: $break-desktop-mid) {
        &--left {
            .mlkam-logo {
                width: 378px;
                height: 78px;
            }
        }
    }
    &--affiliate-links {
        
        background-color: $washington-red;
        &--wrapper {
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &--text {
            font-size: 11px;
            line-height: 18px;
            letter-spacing: -0.25px;
            display: flex;
            // text-decoration: none;
        }
        a {
            display: flex;
            transition: none;
            &:focus {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    }

    @media screen and (max-width: $break-tablet-max) {
        &--affiliate-links {
            .logo {
                width: 190.92px;
                height: 16px;
            }
        }
    }

    @media screen and (min-width: $break-desktop-min) {
        &--affiliate-links {
            background-color: $washington-red;
            &--wrapper {
                height: 35px;
                max-width: 1360px;
                margin: 0 auto;
            }
            &--text {
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.25px;
                .desktop-only {
                    margin-left: 3px;
                }
            }
        }
    }
    @media screen and (min-width: 1440px) {
        .nav--wrapper {
            max-width: 1360px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media screen and (min-width: 1520px) {
        &--affiliate-links {
            &--wrapper {
                max-width: 1440px;
                margin: 0 auto;
            }
        }
        .nav--wrapper {
            max-width: 1440px;
        }
    }
}