.lightbox {
    opacity: 0;
    transition: opacity .6s ease;
    position: fixed;
    z-index: 9;
    overflow: auto;
    pointer-events: none;
    min-height: 100%;
    &.show {
        opacity: 1;
        pointer-events: auto;
    }
    &-image {
        position: relative;
        transition: all .4s ease;
        &--wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: all 0.4s ease;
        }
        .lightbox-play-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            background-color: transparent;
        }
        // &:hover {
        //     .lightbox-image--wrapper {
        //         opacity: 1;
        //         background-color: rgba($color: $stone-black, $alpha: 0.3);
        //     }
        // }
    }
    &-form {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 100%;
        max-width: 720px;
        max-height: calc(100vh - 200px);
        background-color: white;
    }
    &--content {
        padding: 20px;
        position: relative;
        .lightbox-close {
            position: absolute;
            top: 28px;
            right: 20px;
        }
        &-title {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.5px;
            margin-bottom: 20px;
        }
    }
    &-title {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        width: calc(100% - 30px);
    }
    &-text {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.25px;
        p {
            margin-bottom: 25px;
        }
        a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--control {
        &--zoom {
            display: flex;
            .object-lightbox__control--zoom-in {
                margin-right: 15px;
            }
        }
        &--nav {
            display: flex;
            .lightbox-arrow {
                display: none;
            }
        }
    }
    &-single {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        background-color: $salt-white;
        opacity: 0;
        transition: opacity .6s ease;
        pointer-events: none;
        &-trigger {
            &:focus-visible {
                opacity: 1;
                border: 1px solid blue;
                background-color: rgba(27,27,27,.3);
            }
        }
        .zoom-target {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 85%;
            max-height: 100%;
            object-fit: contain;
            &--wrapper {
                position: relative;
                overflow: hidden;
                width: 100%;
                margin-top: 58px;
                height: 85vh;
            }
        }
        .lightbox-multimedia {
            display: none;
        }
        &.show {
            opacity: 1;
            pointer-events: auto;
        }
        &.media {
            .zoom-target--wrapper {
                display: none;
            }
            .lightbox-multimedia {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100% - 100px);
            }
            .object-lightbox__control--zoom, .lightbox--control--zoom {
                display: none;
            }
        }
        &.video {
            video {
                display: block;
                max-width: calc(100vw - 80px);
                max-height: calc(80vh - 100px);
            }
            audio {
                display: none;
            }
        }
        &.audio {
            video {
                display: none;
            }
            audio {
                display: block;
            }
        }
        &--content {
            padding-bottom: 100px;
        }
        &--caption {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.25px;
            > span {
                color: $caption-grey;
            }
            a {
                color: $stone-black;
                margin-left: 5px;
                text-decoration: underline;
                text-underline-offset: 3px;
                &:hover {
                    color: $concrete-grey;
                }
            }
        }
        @media screen and (max-width: $break-tablet-max) {
            &--caption {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.25px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: calc(100% - 40px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 25px 20px;
                background-color: $salt-white;
            }
        }
    }
    a {
        color: $stone-black;
    }
    @media screen and (max-width: $break-tablet-max) {
        &--control {
            &--zoom {
                position: absolute;
                top: 18px;
                left: 8%;
            }

            &--nav {
                position: absolute;
                top: 18px;
                right: 8%;
            }

            .btn {
                &:before {
                    top: 40px;
                    z-index: 1;
                }

                &:after {
                    top: 35px;
                }

                &.object-lightbox__control--zoom-in {
                    &:before {
                        left: -8%;
                    }
                }

                &.close {
                    &:before {
                        right: -8%;
                    }
                }

            }
        }
    }

    @media screen and (min-width: $break-desktop-min) {
        &-form {
            height: 100vh;
            max-height: fit-content;
        }

        &--content {
            padding: 30px;

            .lightbox-close {
                top: 30px;
                right: 30px;
                &:hover {
                    svg {
                        path,
                        line {
                            fill: $stone-black;
                        }
                    }
                }
            }
        }

        &-title {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.75px;
            margin-bottom: 28px;
        }

        &-text {
            font-size: 16px;
            line-height: 24px;
        }

        &-single {
            .zoom-target--wrapper {
                height: 100vh;
                margin-top: 0;
            }

            .zoom-target {
                max-width: 60%;
                max-height: 60%;
            }

            .close {
                margin-right: 40px;
            }
        }

        &--control {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 0;
            background-color: $salt-white;

            &--zoom {
                margin-left: 40px;
                position: absolute;
                left: 0;
            }

            &--nav {
                margin-right: 40px;
                position: absolute;
                right: 0;

                .lightbox-arrow {
                    display: flex;
                    margin-right: 15px;
                    &.swiper-button-disabled {
                        opacity: 0.5;
                        cursor: auto;
                        pointer-events: none;
                    }
                }
            }

            .btn {
                &:before {
                    bottom: 40px;
                    z-index: 1;
                }

                &:after {
                    bottom: 35px;
                }

                &.object-lightbox__control--zoom-in {
                    &:before {
                        left: -20px;
                    }
                }

                &.close {
                    &:before {
                        right: 20px;
                    }
                }

                &:hover {
                    svg {
                        path,
                        line {
                            fill: $stone-black;
                        }
                    }
                }
            }
        }
        &--caption {
            max-width: calc(100% - 320px);
        }
    }
}

.object-lightbox {
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: $salt-white;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.show {
        opacity: 1;
        pointer-events: all;
    }
    &.no-list {
        .zoom-target--wrapper {
            height: 100vh;
        }
        .object-lightbox__control {
            bottom: 0;
            padding-bottom: 30px;
            z-index: 10;
            background-color: transparent;
            &--info--counter {
                display: none;
            }
        }
    }
    svg {
        &.stroke-black:hover {
            path,
            line {
                stroke: $stone-black;
            }
        }

        &:hover {
            circle,
            path,
            line {
                fill: $stone-black;
            }
        }
    }

    // Add any global styles for the .object-lightbox here
    .zoom-target {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        height: 100%;
        object-fit: contain;

        &--wrapper {
            position: relative;
            overflow: hidden;
            width: 100%;
            // Add styles for the canvas element here
            margin-top: 58px;
            height: 85vh;
        }
    }

    &__slider {
        margin: 0;
        // max-height: 77px;
        height: 80px;
        max-width: 85%;
        margin: 20px auto;
        // transform: translate(-34%, 0);
        overflow: visible;


        // &--track {
        //     height: auto;
        // }

        &-wrapper {
            width: 100%;

        }

        .swiper-slide {
            // width: 100px;
            width: auto;
            // height: 77px;
            position: relative;
            cursor: pointer;

            &--overlay {
                position: absolute;

                top: 0;
                left: 0;
                background-color: #000;
                height: 100%;
                opacity: 0;
                width: 100%;
                transition: opacity 0.3s ease-in-out;
                z-index: 1;

                &:hover {
                    opacity: 0.5;
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }

        &--nav {
            position: relative;

            &.hidden {
                opacity: 0;
            }
            &-arrow {
                position: absolute;
                top: -110px;
                z-index: 5;
                background: white;
                left: 0;
                padding: 40px 10px;
                &.object-lightbox--nav-next {
                    left: auto;
                    right: 0;
                    margin-left: auto;
                }
            }
        }
    }

    &__control {
        &--info {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 90%;
            margin: 0 auto;

            &--counter {
                width: 90px;
                @extend .typography-8;
                color: $stone-black;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--title {
                width: calc(100% - 90px);
                @extend .typography-8;
                color: $caption-grey;
            }
        }

        .btn {
            position: relative;
        }
    }

    @media screen and (min-width: $break-desktop-min) {
        .zoom-target {
            width: 60%;
            max-height: calc(100% - 41px);
            &--wrapper {
                margin: 0;
                height: 85vh;
            }
        }
        &__slider {
            min-width: 95%;
            // transform: translate(-43%, 0);
            &-wrapper {
                // width: calc(100% - 80px);
                border-top: 1px solid $clay-grey;
                margin-top: 20px;
            }
        }
        &__control {
            bottom: 130px;
            padding-bottom: 5px;
            width: calc(100% - 80px);
            align-items: center;
            padding-left: 40px;
            padding-right: 40px;
            &--info {
                width: auto;
                &--counter {
                    margin: 0;
                }
                &--title {
                    width: auto;
                }
            }
            &--zoom {
                position: unset;
                margin: 0;
            }
            &--nav {
                position: unset;
                margin: 0;
                .btn.close:before {
                    right: -20px;
                }
            }
        }
    }
}