.dropdown {
    position: relative;
    padding: 10px 0;
    &.show {
        svg {
            transform: rotate(180deg);
        }
    }
    button {
        &:hover {
            .filter-sort--text {
                color: $stone-black;
            }
            &:not(.btn-disabled) {
                svg {
                    path {
                        fill: $stone-black;
                    }
                }
            }
        }
    }
}
.sort {
    &-calendar {
        display: flex;
        align-items: center;
        position: relative;
        &--selected {
            a {
                text-decoration: none;
            }

            :hover,
            a.active {
                color: $stone-black;
            }
        }
        &.show {
            svg {
                transform: rotate(0);
            }
        }
    }

    &-list {
        visibility: hidden;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 4;
        overflow-y: hidden;
        overflow-y: clip;
        box-sizing: border-box;
        transition: max-height 0.5s ease;
        ul {
            padding: 10px;
            padding-left: 30px;
            background-color: white;
            border: 1px solid $rubber-grey;
            &:has(:focus-visible) {
                li {
                    padding: 0 2px;
                }
            }
        }
        a,
        span {
            visibility: hidden;
        }

        &.hide {
            visibility: visible;
            max-height: 0 !important;
        }
        &.show {
            display: block;
            visibility: visible;
            overflow: visible;
            a,
            span {
                visibility: visible;
            }
        }
        &-item {
            margin-bottom: 2px;
            white-space: nowrap;
            button,
            a {
                text-align: right;
                padding: 0 20px 0 30px;
                span {
                    text-decoration: underline;

                    text-decoration-color: transparent;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 1px;
                    transition: all 0.4s ease;
                }
                &:hover,
                &.active {
                    color: $stone-black;
                    span {
                        text-decoration-color: $stone-black;
                        &.active {
                            text-decoration-color: $stone-black;
                        }
                    }
                }
            }
            button {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                line-height: 1.15;
                height: 25px;
                align-items: center;
            }
        }
        a {
            text-decoration: none;
            justify-content: flex-end;
            &:hover {
                color: $stone-black;
            }
        }
    }

    &-selected {
        svg {
            margin-left: 8px;
        }
    }

    @media screen and (min-width: $break-tablet-min) {
        &-list {
            ul {
                padding: 15px 0;
                max-height: 60vh;
                overflow-y: auto;
            }
        }
    }

    @media screen and (min-width: $break-desktop-min) {
        &-calendar {
            &--selected {
                margin-right: 10px;
            }
        }
        &-list {
            min-width: 200px;

            &-item {
                font-size: 21px;
                line-height: 29px;
                text-align: right;
                letter-spacing: -0.4px;
            }
        }
    }
}
.calendar {
    padding: 20px 0;
    background-color: white;
    border: 1px solid $rectangle-grey;
    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin-bottom: 20px;
    }
    .monthAndYear {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.25px;
        width: calc(100% - 84px);
    }
    &-arrows {
        display: flex;
        align-items: center;
        .btn {
            margin-left: 30px;
            &.btn-disabled {
                color: $clay-grey;
                svg path {
                    fill: $clay-grey;
                }
            }
        }
    }
    table {
        margin: 0 10px;
        border-spacing: 0px 5px;
        border-collapse: separate;
    }
    &-body {
        td {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding: 5px 8px;
            text-align: center;
            color: $concrete-grey;
            &.selectable {
                transition: all 0.2s ease-in-out;
                color: black;
                border-radius: 35px;
                cursor: pointer;
                &:hover,
                &.active {
                    color: $salt-white;

                    background-color: $stone-black;
                }
            }
            &.bg-info {
                color: $salt-white;
                border-radius: 35px;
                background-color: $stone-black;
                &.excluded {
                    text-decoration: underline;
                }
            }
            &.excluded {
                border-radius: 35px;
                background-color: $light-grey;
                pointer-events: none;
                color: $concrete-grey;
                // dark magic
                &:has(+ td.excluded){
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    + td.excluded {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        .monthAndYear {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.4px;
            width: calc(100% - 84px);
        }
        &-body {
            td {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.4px;
                padding: 5px 8px;
                text-align: center;
            }
        }
    }
}

.accordion {
    max-height: 0;
    overflow: hidden;
    overflow: clip;
    //display: none;
    transition: max-height 0.5s ease;
    // &.show {
    //     display: block;
    // }
    &.overflow {
        overflow-y: auto;
    }
    &.show {
        &:has(:focus-visible) {
            overflow: visible;
        }
    }
    &-wrapper {
        &:last-child {
            border-bottom: 1px solid $rubber-grey;
        }
    }
    &-container {
        margin-bottom: 30px;
        & + .carousel {
            margin-top: 85px;
        }
        & + .list-module {
            margin-top: 60px;
        }
    }
    > p {
        margin-bottom: 30px;
        @extend .typography-5;
        width: 100%;
    }
    a {
        //text-decoration: underline;
        text-underline-offset: 4px;
        color: $stone-black;
        text-decoration-thickness: 1px;
        &:hover {
            color: $concrete-grey;
        }
    }
    &-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        > * {
            width: 100%;
        }
    }
    &-image {
        width: 100%;
        float: left;

        img {
            width: 100%;
        }
        figcaption {
            @extend .typography-8;
            color: $concrete-grey;
            margin: 16px 0;
        }
    }
    &--with-image p {
        margin-left: auto;
    }
    &-button {
        background-color: white;
        border: 1px solid $stone-black;
        color: $stone-black;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        // text-decoration: none;
        padding: 10px 20px;
        margin-bottom: 30px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s ease;
        letter-spacing: 0.75px;
        &:hover {
            // text-decoration: underline;
            // text-decoration-thickness: 1px;
            background-color: $stone-black;
            color: $salt-white;
        }
    }
    &-toggle {
        cursor: pointer;
        &.active svg {
            transform: rotate(90deg);
            path:nth-child(2) {
                opacity: 0;
            }
        }
        &:hover svg path {
            stroke: $concrete-grey;
        }

        svg {
            transition: transform 0.5s ease;
            margin-right: 10px;

            path:nth-child(2) {
                transition: opacity 0.5s ease;
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $stone-black;
        padding: 20px 0;
        align-items: center;
        width: 100%;
        &--title {
            @extend .typography-4;
            color: $stone-black;
            text-align: left;
            width: calc(100% - 35px);
        }
    }

    &-title {
        @extend .typography-6;
        color: $concrete-grey;
        padding-bottom: 14px;
    }
    @media screen and (max-width: $break-desktop-min) {
        &-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }
        &-header h6 {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }

        & > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        &-image figcaption {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }
        &-button {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.6000000238418579px;
        }
    }

    @media screen and (min-width: $break-desktop-min) {
        &-container {
            margin-bottom: 50px;
            & + .list-module {
                margin-top: 80px;
            }
        }
        &--with-image {
            flex-wrap: nowrap;
        }
        &-image {
            width: 40%;
            margin-right: 44px;
        }
        &-text {
            width: 60%;
        }
    }
}