@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
    @font-face {
        font-family: $font-family;
        // src: url('#{$file-path}.eot');
        // src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
        //     url('#{$file-path}.woff') format('woff'),
        //     url('#{$file-path}.woff2') format('woff2');
        // src: url('#{$file-path}.otf') format('opentype');
        src: url('#{$file-path}.woff') format('woff'),
            url('#{$file-path}.woff2') format('woff2');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}

@include font-face('Akkurat', '../fonts/AkkuratLLWeb-Regular', 400, normal);
@include font-face('Akkurat', '../fonts/AkkuratLLWeb-Italic', 400, italic);
// @include font-face('Akkurat', '../fonts/Akkurat', 400, normal);
// @include font-face('Akkurat', '../fonts/Akkurat-italic', 400, italic);

body {
    font-family: 'Akkurat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.typography-1, .page-title {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: -1.5px;
}

.typography-2, .homepage-intro {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
}

.typography-3, .sub-title {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.75px;
}

.typography-4 {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.75px;
}

.typography-5 {
    font-size: 23px;
    line-height: 32px;
    letter-spacing: -0.25px;
}

.typography-6 {
    font-size: 21px;
    line-height: 27px;
    letter-spacing: -0.25px;
}

.typography-7 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
}

.typography-8 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
}

.typography-9 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
}

.typography-10 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.75px;
}

.uppercase {
    text-transform: uppercase;
}
.italic {
    font-style: italic;
}