.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        width: calc(100% - 20px);
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.25px;
        &::placeholder {
            color: $concrete-grey;
        }
    }
    &-icon {
        @media screen and (max-width: $break-mobile-max) {
            width: 11px;
            height: 11px;
        }
    }
    &.show {
        .autocomplete {
            // display: block;
            visibility: visible;
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        input {
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -.4px;
            width: calc(100% - 30px);
        }
    }
}

.autocomplete {
    // display: none;
    visibility: hidden;
    position: absolute;
    // width: 100%;
    width: 100vw;
    left: -20px;
    top: calc(100% + 10px);
    background-color: white;
    z-index: 2;
    overflow-y: hidden;
    box-sizing: border-box;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: ease;
    max-height: calc(100vh - 50px);
    overflow: auto;
    > ul {
        margin: 0 20px 0 20px;
    }
    &:hover {
        color: $clay-grey;
    }
    &.hide {
        padding-top: 0;
        padding-bottom: 0;
        max-height: 0 !important;
    }
    &-group {
        border-top: 1px solid $stone-black;
        &:hover {
            .autocomplete-group--title {
                color: $stone-black;
            }
        }
    }
    &-list {
        > li {
            transition: color .4s ease;
            &:hover {
                color: $stone-black;
            }
        }
        &--title {
            max-width: calc(100% - 100px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        // &--number {
        //     padding-right: 16px;
        // }
    }
    @media screen and (min-width: $break-tablet-min) {
        left: -30px;
        > ul {
            margin: 0 30px 0 30px;
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        position: absolute;
        // width: 100%;
        top: 100%;
        left: -40px;
        background-color: white;
        z-index: 2;
        // box-shadow: 1px 9px 10px 0px $rectangle-grey;
        box-shadow: 0 14px 9px -9px $clay-grey;
        > ul {
            margin: 0 40px;
        }
        &-group {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 15px;
            &--title {
                width: 20%;
                min-width: 230px;
            }
        }
        &-list {
            width: 80%;
            margin-left: 20px;
            max-width: calc(100% - 250px);
            &--item {
                position: relative;
                padding: 15px 0 17px 0;
                border-top: 1px solid $rubber-grey;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:first-of-type {
                    border-top: none;
                    padding-top: 0;
                }
            }
            a {
                color: transparent;
                &:hover, &:focus {
                    color: transparent;
                }
            }
            &--title {
                max-width: calc(100% - 150px);
            }
            &--number {
                padding-right: 0;
            }
            
            &--viewall {
                padding-top: 10px;
                &-text {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.25px;
                }
            }
        }
    }
    @media screen and (min-width: 1441px) {
        left: calc(-50vw + 680px);
    }
    @media screen and (min-width: 1520px) {
        left: calc(-50vw + 720px);
        > ul {
            width: 1440px;
            margin: 0 auto;
        }
    }
}