.home {
    .intro {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.25px;
        max-width: 800px;
        // margin-bottom: 30px;
        margin: 60px 0;
        a {
            color: $stone-black;
            &:hover {
                color: $concrete-grey;
            }
        }
    }
    &-banner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        &-track {
            a {
                // text-decoration: none;
                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 1px;
                    border: 2px solid blue;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    border-radius: 5px;
                }
            }
            height: auto;
            margin-bottom: 60px;
            img {
                height: 60vh;
                width: 100%;
                overflow: hidden;
                object-fit: cover;
            }
            .list-item {
                flex-wrap: wrap;
                &--image {
                    width: 100%;
                }
            }
        }
        &-description {
            margin-top: 15px;
        }
        &-warning {
            @extend .typography-9;
        }
        &-title {
            font-size: 22px;
            line-height: 28px;
            color: $stone-black;
            letter-spacing: -0.75px;
            margin: 4px 0;
        }
        &-duration {
            @extend .typography-6;
            letter-spacing: -0.4px;
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        .intro {
            margin: 20px 0 40px 0;
        }
    }
    @media screen and (max-width: $break-tablet-max) {
        &-banner {
            &-warning {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
            }
            &-duration {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        .feature--large {
            max-width: 1190px;
            margin: 40px auto;
        }
        &-banner {
            &-nav {
                display: flex;
                margin: 15px 0;
                padding-right: 1px;
                gap: 25px;
                position: relative;
                z-index: 1;
                &--arrow {
                    height: 30px;
                    &:hover, &:focus {
                        svg {
                            line, path {
                                stroke: $stone-black;
                            }
                        }
                    }
                }
            }
            &-description {
                max-width: 60%;
            }
            &-title {
                font-size: 40px;
                line-height: 42px;
                letter-spacing: -1.5px;
                margin: 6px 0 8px;
            }
            &-track {
                margin-bottom: 0;
            }
        }
        .intro {
            font-size: 34px;
            line-height: 42px;
            letter-spacing: -1px;
            max-width: 860px;
            margin: 100px 0;
            a {
                text-decoration-thickness: 2px;
                text-underline-offset: 4px;
            }
        }
    }
}

.feature-homepage {
    @media screen and (min-width: $break-tablet-min) {
        margin: 50px 0 30px 0;
    }
    @media screen and (min-width: $break-desktop-min) {
        margin: 100px 0 50px 0;
        &.feature-large {
            margin: 70px 0 100px 0;
        }
    }
}