.header-title {
    margin-top: 40px;
    margin-bottom: 40px;
    &--no-space {
        margin-bottom: 0;
    }
    &--user {
        margin: 30px 0;
        .page-title {
            margin: 0;
        }
    }
    &.small_logo {
        margin-top: 30px;
    }
    .breadcrumb {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
        margin-bottom: 8px;
        &-bar {
            margin: 0 3px;
        }
        a {
            // text-decoration: none;
            &:hover {
                color: $stone-black;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;

            }
        }
    }
    .page {
        &-title {
            font-size: 28px;
            line-height: 30px;
            letter-spacing: -1px;
            margin-bottom: 12px;
            max-width: 900px;
        }
        &-subtitle {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.25px;
            max-width: 900px;

            a {
                color: $concrete-grey;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
                &:hover {
                    color: $stone-black;
                }
            }
        }
    }
    &+.filter--wrapper {
        .filter-accordion {
            padding-top: 0;
        }
    }
    &+.list-module {
        .main-bodytext--chapter-title {
            padding-top: 0;
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        margin-bottom: 50px;
        &--no-space {
            margin-bottom: 0;
        }
        &--user {
            margin: 40px 0;
            .page-title {
                margin: 0;
            }
        }
        &.small_logo {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .breadcrumb {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            margin-bottom: 6px;
        }
        .page {
            &-title {
                font-size: 40px;
                line-height: 42px;
                letter-spacing: -1.5px;
            }
            &-subtitle {
                font-size: 23px;
                line-height: 32px;
            }
        }
    }
}

.body-header {
    .cover-image {
        width: 100%;
        height: 60vw;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &+.filter--wrapper {
        margin-top: 20px;
    }
    @media screen and (min-width: $break-desktop-min) {
        margin-bottom: 30px;
        .cover-image {
            height: 620px;
        }
    }
}