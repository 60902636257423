.feature {
    &--type {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
        margin-bottom: 4px;
    }
    &--title {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.4px;
    }
    &--text {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.4px;
    }
    &--date {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.4px;
    }
    &-small {
        .feature--title {
            margin-bottom: 6px;
        }
    }
    &-large {
        .feature--title {
            margin-bottom: 8px;
        }
        .list-item {
            display: block;
            &--content {
                display: block;
            }
        }
        img {
            max-height: none;
        }
        video {
            width: 100%;
        }
    }
    &--large {
        display: flex;
        flex-direction: column;
        &__image {
            width: 100%;
            height: auto;
            margin-bottom: 14px;
            position: relative;
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 400px;
            }
            video {
                width: 100%;
            }
        }
        &__content {
            height: 100%;
            &-pretitle {
                color: $concrete-grey;
                margin-bottom: 2px;
                text-transform: uppercase;
                @extend .typography-9;
            }
            &-title {
                margin-bottom: 10px;
                @extend .typography-4;
            }
            &-description {
                margin-bottom: 10px;
                @extend .typography-7;
                color: $concrete-grey;
                &.bodytext p {
                    @extend .typography-7;
                }
                a {
                    color: $concrete-grey;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                    &:hover {
                        color: $stone-black;
                    }
                    em {
                        font-style: italic;
                    }
                }
            }
        }
    }
    &-top {
        margin-top: 60px;
    }
    @media screen and (max-width: $break-desktop-min) {
        &--large__content {
            &-pretitle {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }
            &-title {
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.75px;
            }
            &-description {
                font-size: 14px;
                line-height: 1.4;
                &.bodytext p {
                    font-size: 14px;
                    line-height: 1.4;
                }
            }
        }
        &-homepage {
            .list-module--body .list-item {
                margin-bottom: 60px;
            }
            > .list-item {
                margin-bottom: 60px;
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &--type {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 6px;
        }
        &--title {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.75px;
        }
        &--text {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.25px;
        }
        &--date {
            font-size: 21px;
            line-height: 25px;
        }
        &-small {
            .feature--title {
                margin-bottom: 8px;
            }
        }
        &-large {
            .list-item {
                display: flex;
                justify-content: space-between;
                &--image {
                    width: 61%;
                }
                &--content {
                    margin-top: 0;
                    max-width: 350px;
                }
            }
            &.feature-homepage {
                .list-item {
                    max-width: 1149px;
                    margin: 0 auto;
                    align-items: center;
                }
            }
            .feature--content {
                width: 30.5%;
            }
            .feature--title {
                margin-bottom: 13px;
            }
        }
        &--large {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: 40px 0;
            gap: 40px;
            &__image {
                min-width: 65%;
                max-width: 65%;
                height: auto;
                margin-bottom: 0;
            }
            &__content {
                margin-bottom: 0;
                &-pretitle {
                    margin-bottom: 6px;
                }
                &-title {
                    margin-bottom: 10px;
                }
                &-description {
                    margin-bottom: 0;
                }
            }
        }
        &-top {
            margin-top: 100px;
        }
    }
}