.bodytext {
    color: $stone-black;
    p {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.25px;
        margin-bottom: 15px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    a {
        color: $stone-black;
        text-decoration: underline;
        text-decoration-color: currentColor;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        &:hover {
            color: $concrete-grey;
        }
        &.btn{
            // text-decoration: none;
            &:hover {
                color: #ffffff;
            }
        }
    }
    em, i {
        font-style: italic;
    }
    small {
        display: block;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.25px;
    }
    img, video {
        max-width: 100%;
        height: auto;
    }
    table {
        max-width: 100%;
    }
    table, tbody, thead, tr, td, th {
        border: 1px;
        border-style: solid;
        vertical-align: middle;
        p {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            &:last-of-type {
                margin-bottom: 1em;
            }
        }
    }
    blockquote {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.6px;
        margin: 30px 0;
        display: flex;
    }
    h1 {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.6px;
        margin: 0;
    }
    h2 {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.6px;
        color: $concrete-grey;
    }
    h3 {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.5px;
    }
    h4 {
        font-size: 10px;
        line-height: 16px;
        letter-spacing: -0.5px;
    }
    h5 {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: -0.4px;
        text-transform: uppercase;
    }
    h6 {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: $concrete-grey;
    }
    a.btn, button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        padding: 0 15px;
        margin: 20px 0;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-decoration: none;
        border: 1px solid $stone-black;
        width: fit-content;
        &:hover {
            color: $salt-white;
            background-color: $stone-black;
        }
    }
    ul {
        list-style: disc;
        padding-left: 15px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.25px;
    }
    ol {
        list-style: auto;
        padding-left: 27px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.25px;
    }
    .main-bodytext {
        &--chapter {
            &-title {
                font-size: 20px;
                line-height: 22px;
                letter-spacing: -0.75px;
                padding: 30px 0 15px 0;
                color: $stone-black;
            }
            &-wrapper {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
            }
            &-content {
                > *:first-child:not(.bodytext-image) {
                    margin-top: 20px;
                }
            }
        }
    }
    &-media {
        &--image {
            width: 100%;
            height: 60vw;
            position: relative;
        }
    }
    &-image {
        display: table;
        margin: 20px 0 15px;
        // width: 100%;
        .lightbox-image {
            display: table;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 640px;
        }
        .list-item--image.no-image {
            width: calc(100vw - 40px);
        }
    }
    &-figcaption {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.25px;
        color: $concrete-grey;
        display: table-caption;
        caption-side: bottom;
        margin-top: 10px;
    }
    iframe {
        width: calc(100vw - 57px);
        height: calc(56vw - 30px);
    }
    .lightbox-image {
        &--wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: all .4s ease;
            &:focus-visible {
                opacity: 1;
                border: 1px solid blue;
                background-color: rgba(27,27,27,.3);
            }
        }
        &:hover {
            .lightbox-image--wrapper {
                opacity: 1;
                border: none;
                background-color: rgba(27,27,27,.3);
            }
        }
    }
    &+.list-module {
        margin-top: 30px;
    }
    &+.carousel {
        margin-top: 60px;
    }
    &+.accordion-container {
        margin-top: 30px;
    }
    @media screen and (min-width: $break-tablet-min) {
        &-image {
            .list-item--image.no-image {
                width: calc(100vw - 60px);
                max-height: 500px;
            }
        }
        iframe {
            width: calc(100vw - 77px);
            height: calc(56vw - 42px);
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        iframe {
            height: auto;
            width: auto;
        }
        p {
            font-size: 23px;
            line-height: 32px;
            margin-bottom: 30px;
        }
        small {
            font-size: 16px;
            line-height: 24px;
        }
        blockquote {
            font-size: 40px;
            line-height: 42px;
            letter-spacing: -1.5px;
            max-width: 800px;
            margin: 40px auto;
        }
        h1 {
            font-size: 40px;
            line-height: 42px;
            letter-spacing: -1.5px;
        }
        h2 {
            font-size: 40px;
            line-height: 42px;
            letter-spacing: -1.5px;
        }
        h3 {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.75px;
        }
        h4 {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.25px;
        }
        h5 {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
        h6 {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
        a.btn, button {
            height: 40px;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.75px;
            margin: 30px 0;
        }
        ul {
            padding-left: 23px;
            font-size: 23px;
            line-height: 32px;
        }
        ol {
            padding-left: 40px;
            font-size: 23px;
            line-height: 32px;
        }
        .object-description {
            max-width: 950px;
        }
        .main-bodytext {
            &--chapter {
                &-title {
                    font-size: 26px;
                    line-height: 32px;
                    letter-spacing: -0.5px;
                    padding: 30px 0 10px 0;
                }
                &-wrapper {
                    margin-top: 10px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    &.no-wrapper {
                        flex-wrap: nowrap;
                    }
                }
                &-content {
                    p, small, h1, h2, h3, h4, h5, h6, ul, ol {
                        max-width: 960px;
                    }
                }
                &-right {
                    max-width: 365px;
                    // margin-top: 80px;
                    margin-left: 95px;
                }
            }
        }
        &-media {
            width: 100%;
            max-width: 1130px;
            margin: 60px auto;
            &--image {
                height: 550px;
            }
            &--caption {
                font-size: 16px;
                line-height: 24px;
                max-width: 620px;
            }
        }
        &-figcaption {
            margin-top: 12px;
        }
        &-image {
            margin: 40px 0 30px;
            .list-item--image.no-image {
                width: 1000px;
            }
        }
        &+.accordion-container {
            margin-top: 50px;
        }
    }
}

.main-bodytext {
    &--chapter {
        &-title {
            font-size: 20px;
            line-height: 22px;
            letter-spacing: -0.75px;
            padding: 30px 0 15px 0;
            color: $stone-black;
        }
        &-wrapper {
            margin-top: 15px;
        }
        &-content {

        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &--chapter {
            &-title {
                font-size: 26px;
                line-height: 32px;
                letter-spacing: -0.5px;
                padding:  30px 0 10px 0;
            }
            &-wrapper {
                margin-top: 10px;
                margin-bottom: 17px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

            }
            &-content {
                p, small, h1, h2, h3, h4, h5, h6, ul, ol {
                    max-width: 960px;
                }
            }
            &-right {
                max-width: 365px;
                // margin-top: 80px;
                margin-left: 95px;
            }
        }
    }
}

::selection {
    background: $stone-black;
    color: $salt-white;
}