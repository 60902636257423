a, button {
    padding: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    text-decoration-color: transparent;
    text-decoration-thickness: 0px;
    line-height: inherit;
    svg, svg path, svg circle, svg rect, svg line {
        transition: all .4s ease;
    }
    &:hover {
        text-decoration-color: currentColor;
        text-decoration-thickness: 1px;
        
    }
    &:focus-visible {
        outline: blue auto 1px;
    }
}
* {
    transition: color 0.4s ease, text-decoration-color 0.4s ease;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // text-decoration: none;
    &-border {
        border: 1px solid;
        &:hover {
            &.border--stone-black {
                color: $salt-white;
                background-color: $stone-black;
            }
            &.bg--stone-black {
                color: $stone-black;
                background-color: $salt-white;
            }
        }
    }
    &-no-border {
        border: none;
        // border: 1px solid $salt-white;
        // &:focus, &:hover {
        //     border: none;
        // }
        // &:focus-visible {
        //     border: 1px solid blue;
        // }
    }
    &-disabled {
        cursor: auto;
    }
    &-request {
        height: 35px;
        padding: 0 15px;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.5px;
    }
    &-lightbox-form {
        height: 40px;
        padding: 0 20px;
        @extend .typography-10;
    }
    @media screen and (min-width: $break-desktop-min) {
        &-request {
            height: 40px;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.75px;
        }
    }
}

.user-fav {
    .fav-icon {
        display: none;
    }
    &.fav-add {
        .icon-heart {
            display: block;
        }
        &:hover {
            .icon-heart {
                display: none;
            }
            .icon-check {
                display: block;
            }
        }
    }
    &.fav-remove {
        .icon-check {
            display: block;
        }
        &:hover {
            .icon-check {
                display: none;
            }
            .icon-x {
                display: block;
            }
        }
    }
}

a.view-all {
    text-underline-offset: 4px;
    &:hover {
        color: $stone-black;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
    }
    @media screen and (min-width: $break-desktop-min) {
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -.4px;
    }
}

.loader {
    border: 2px solid #c3c8cb;
    
    border-top: 2px solid #a51417;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    max-width: 20px;
    margin: 0 auto;
    position: relative;
    display: none;
    &.show {
        display: block;
    }   
    @media screen and (min-width: $break-desktop-min) {
        
        top: -10px;
        width: 40px;
        height: 40px;
        max-width: 40px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}