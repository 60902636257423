input {
    border: none;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
        -moz-appearance: textfield;
    }
}
.input-group {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    margin-bottom: 5px;
    position: relative;
    &--hidden {
        margin: 0;
        height: 0;
    }
    &--no-margin {
        margin-top: 0;
    }
    &--label, &--helptext {
        display: block;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.25px;
        margin-bottom: 7px;
    }
    &--errortext {
        position: absolute;
        bottom: -28px;
        &:not(.form-input--error) {
            top: calc(100% + 10px);
        }
        @media screen and (max-width: $break-mobile-max) {
            bottom: 0;
        }
    }
    // &--bottom-margin {
    //     margin-bottom: 70px;
    // }
    &--text {
        width: 100%;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.4px;
        margin-bottom: 8px;
        margin-left: -3px;
    }
    &--space-between {
        justify-content: space-between;
    }
    &--half {
        width: 100%;
        @media screen and (max-width: $break-mobile-max) {
            &:first-of-type {
                margin-bottom: 20px;
            }
        }
    }
    &--radiogroup {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // margin-bottom: 15px;
        input {
            display: none;
            &:checked + label {
                color: white;
                background-color: $stone-black;
            }
        }
        label {
            margin-top: 7px;
            margin-right: 18px;
            cursor: pointer;
            &:hover {
                color: white;
                background-color: $stone-black;
            }
        }
    }
    &--checkbox {
        align-items: flex-start;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: 20px;
        &:first-of-type {
            margin-top: 0;
        }
        input {
           width: 18px;
           height: 18px;
           margin-top: 4px;
           accent-color: $stone-black;
        }
        label {
            margin-left: 5px;
            margin-bottom: 0;
            width: calc(100% - 20px);
            cursor: pointer;
        }
        &+.input-group--helptext {
            margin-top: 20px;
            a {
                text-decoration: underline;
            }
        }
    }
    &--datetime {
        width:100%;
        display: flex;
        justify-content: space-between;
        
        .datetime-wrapper {
            position: relative;
            width: 20% !important;
            display: flex;
            align-items: center;
            &:has(input[type="time"]) {
                width: 15%;
            }
            select {
                margin-bottom: 0;
            }
            &:hover {
                svg path {
                    fill: $stone-black;
                }
            }
        }
    }
    &.error {
        margin-bottom: 30px;
    }
    input[type="time"] {
        font-family: Akkurat;
        font-size: 21px;
        line-height: 25px;
        &.pure-time {
            padding-bottom: 5px;
            margin-left: auto;
        }
    }
    select {
        width: 100%;
        border: none;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -.4px;
        margin-left: -5px;
        margin-bottom: 5px;
        background: #fff;
        cursor: pointer;
    }
    .select-arrow {
        position: absolute;
        right: 7px;
        bottom: 10px;
        height: 15px;
        background-color: white;
        cursor: pointer;
        pointer-events: none;
        
    }
    textarea {
        border: none;
    }
    .open-calendar {
        padding-left: 25px;
        &--wrapper {
            position: absolute;
            bottom: 3px;
            .sort-list {
                left: 0;
                right: auto;
            }
            svg {
                margin-left: 0;
            }
        }
    }
    svg path {
        transition: fill 0.4s ease;
    }
    &:hover {
        svg path {
            fill: $stone-black;
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        &--half {
            position: relative;
            width: calc(50% - 10px);
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &--label, &--helptext {
            font-size: 16px;
            line-height: 24px;
        }
        &--text {
            width: 100%;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.4px;
        }
    }
}

.form-error, .form-input--error {
    color: $salt-white;
}

.label-none {
    position: absolute;
    color: transparent;
    text-indent: -9999px;
}

.auth {
    &-menu {
        display: flex;
        &--tab {
            a {
                color: $stone-black;
                opacity: 0.5;
                // text-decoration: none;
                margin-right: 20px;
            }
            &.active, &:hover {
                a {
                    opacity: 1;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
            }
        }
    }
    &-form {
        max-width: 800px;
        &--bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            .btn:not(.btn-border) {
                white-space: nowrap;
                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 4px;
                }
            }
            &.one-btn {
                justify-content: flex-end;
            }
            .input-group {
                margin-top: 0;
            }
            .btn-border {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 1px;
                padding: 11px 18px;
            }
            @media screen and (max-width: $break-mobile-mid) {
                flex-wrap: wrap;
                .input-group {
                    width: 100%;
                    &--btn {
                        margin-top: 20px;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    &-message {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -.25px;
        margin-bottom: 20px;
    }
    &-input {
        margin-bottom: 20px;
    }
    &-error {
        font-size: 12px;
        margin-top: 5px;
        color: $salt-white;
    }
    &-forgot-link {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -.25px;
        color: $stone-black;
        opacity: 0.5;
        // text-decoration: none;
        margin-right: 20px;
        &:hover {
            opacity: 1;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
        }
    }
    .input-group {
        &--text {
            margin-bottom: 13px;
        }
        &--checkbox {
            margin-top: 0;
            label {
                color: $stone-black;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -.25px;
            }
            a {
                color: $stone-black;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
                &:hover {
                    opacity: 0.5;
                }
            }
            @media screen and (max-width: $break-mobile-max) {
                label {
                    font-size: 11px;
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        &-form {
            &--bottom {
                .btn:not(.btn-border) {
                    font-size: 21px;
                    line-height: 27px;
                    letter-spacing: -.25px;
                }
            }
        }
        &-input {
            margin-bottom: 30px;
        }
        &-error {
            font-size: 14px;
        }
    }
}

.form-builder {
    .lightbox {
        &--content {
            border-bottom: 1px solid $clay-grey;
            &:last-of-type {
                border-bottom: none;
            }
        }
        @media screen and (max-width: $break-tablet-max) {
            &-form--content {
                padding: 35px 20px;
                .lightbox-title, &.lightbox-text h2 {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.5px;
                }
                .input-group {
                    margin-top: 15px;
                    &:first-of-type {
                        margin-top: 0;
                    }
                    &--checkbox {
                        input {
                            margin-top: 3px;
                        }
                        label {
                            margin-left: 10px;
                        }
                        .input-group--text {
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: -0.25px;
                        }
                    }
                    &--text {
                        color: $stone-black;
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: -0.5px;
                        margin-bottom: 5px;
                        &::placeholder {
                            color: $stone-black;
                        }
                    }
                }
            }
        }
    }
    .lightbox-text {
        h2 {
            @extend .lightbox-title;
        }
    }
    .form-error, .form-input--error {
        color: $washington-red;
    }
}

.grecaptcha-badge {
    display: none !important;
}