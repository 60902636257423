.carousel {
    margin-top: 30px;
    // margin-left: -20px;
    // padding-left: 20px;
    margin-bottom: 20px;
    &:has(:focus-visible) {
        padding-left: 3px;
        padding-right: 3px;
        padding-bottom: 2px;
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -10px;
        margin-bottom: 20px;
        padding-top: 10px;
    }
    .view-all {
        // text-decoration: none;
        &:hover {
            color: $stone-black;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
        }
    }
    &-tab {
        display: flex;
        margin-top: -1px;
        z-index: 1;
        &-module {
            .carousel {
                display: none;
                padding-top: 90px;
                top: -80px;
                z-index: 0;
                &.active {
                    display: block;
                }
                .blur {
                    opacity: 0;
                    transition: all 0.4s ease;
                }
                &-arrows {
                    position: absolute;
                    top: 0px;
                    right: 0;
                }
                .view-all {
                    position: absolute;
                    top: 65px;
                }
            }
        }
        &--item {
            margin-right: 10px;
            border-top: 2px solid transparent;
            &:hover, &.active {
                color: $stone-black;
            }
            &.active {
                border-top: 2px solid $stone-black;
            }
        }
        &--wrapper {
            padding: 0;
            a {
                padding-top: 10px;
            }
        }
    }
    &-arrows {
        display: flex;
        margin-left: 10px;
    }
    &-arrow {
        border: none;
        background-color: transparent;
        &.swiper-button-disabled {
            cursor: unset;
            opacity: 0.5;
            svg {
                fill: $clay-grey;
            }
        }
        &:hover {
            svg {
                fill: $concrete-grey;
            }
        }
        &--next {
            margin-left: 40px;
        }
        svg {
            transition: fill .4s ease;
            fill: $stone-black;
        }
        
    }
    &-contents {
        display: flex;
        position: relative;
        height: auto;
        // margin-bottom: 20px;
    }
    &-coloums-3, &-coloums-4 {
        .list-item {
            &--image {
                img {
                    max-height: 240px;
                }
                &.no-image {
                    width: calc(50vw - 30px);
                }
            }
        }
    }
    .list-item {
        display: block;
    }
    &:not(.swiper-initialized) {
        .list-item {
            margin-right: 20px;
        }
    }
    .shimmer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;
        background-color: white;
        display: none;
        .list-item {
            margin-right: 20px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    @media screen and (min-width: $break-tablet-min) {
        // margin-left: -30px;
        // padding-left: 30px;
        margin-bottom: 40px;
        &-tab {
            &-module {
                .carousel {
                    padding-top: 80px;
                    top: -80px;
                    &-arrows {
                        position: absolute;
                        top: 0px;
                        right: 0;
                    }
                    .view-all {
                        top: 45px;
                        right: 0;
                    }
                }
            }
        }
        &-coloums-3 {
            .list-item {
                &--image {
                    img {
                        max-height: 360px;
                    }
                    &.no-image {
                        width: calc((100vw - 140px) / 3);
                    }
                }
            }
        }
        &-coloums-4 {
            .list-item {
                &--image {
                    img {
                        max-height: 360px;
                    }
                    &.no-image {
                        width: calc(25vw - 45px);
                    }
                }
            }
        }
    }
    @media screen and (min-width: $break-desktop-min) {
        margin-top: 50px;
        // margin-left: -40px;
        // padding-left: 40px;
        margin-bottom: 80px;
        &-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: -20px;
            padding-top: 15px;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.4px;
            a {
                // text-decoration: none;
            }
        }
        &-tab {
            &--item {
                padding-top: 15px;
                margin-right: 14px;
            }
            &--wrapper {
                padding: 0;
                margin-bottom: 30px;
                a {
                    padding-top: 15px;
                }
            }
            &-module {
                .carousel {
                    padding-top: 120px;
                    top: -120px;
                    &-arrows {
                        position: absolute;
                        top: 0px;
                        right: 0;
                    }
                    .view-all {
                        top: 65px;
                        font-size: 21px;
                        line-height: 25px;
                        letter-spacing: -.4px;
                    }
                }
            }
        }
        &-coloums-3 {
            .list-item {
                &--image {
                    &.no-image {
                        width: calc((100vw - 160px) / 3);
                    }
                }
            }
        }
        &-coloums-4 {
            .list-item {
                &--image {
                    &.no-image {
                        width: calc(25vw - 50px);
                        max-width: 330px;
                    }
                }
            }
        }
        .shimmer {
            .list-item {
                margin-right: 50px;
            }
        }
        &:not(.swiper-initialized) {
            .list-item {
                margin-right: 50px;
            }
        }
        &-arrow:hover {
            svg {
                fill: $concrete-grey;
            }
        }
    }
    // @media screen and (min-width: $break-desktop-min) {
    //     margin-left: -60px;
    //     padding-left: 60px;
    // }
}

.swiper {
    &-button-lock {
        display: none;
    }
}