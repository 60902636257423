/*--------------------------------------------------------------
Break Points
--------------------------------------------------------------*/
$break-desktop-xxl:1920px;
$break-desktop-max:1520px;
$break-desktop-mid:1280px;
$break-desktop-min:1080px;
$break-tablet-max:1079px;
$break-tablet-min:769px;
$break-mobile-max:768px;
$break-mobile-mid:450px;
$break-mobile-min:320px;

/*--------------------------------------------------------------
Colours
--------------------------------------------------------------*/
$stone-black:    #1B1B1B;
$salt-white:     #FFFFFF;
$washington-red: #A51417;
$rubber-grey:    #C3C8CB;
$concrete-grey:  #60666D; // hover state
$clay-grey:      #879196;
$rectangle-grey: #D8D8D8;
$dark-grey:      #3C3D3D;
$caption-grey:   #5D6670;
$light-grey:     #F2F2F2;
$off-white:      #FCFCFC;
$footer-grey:    #D1D1D1;
$transparent: 'transparent';
$temp-yellow: 'yellow';

$colors: (
    'stone-black': $stone-black,
    'salt-white': $salt-white,
    'washington-red': $washington-red,
    'rubber-grey': $rubber-grey,
    'concrete-grey': $concrete-grey,
    'clay-grey': $clay-grey,
    'rectangle-grey': $rectangle-grey,
    'dark-grey': $dark-grey,
    'caption-grey': $caption-grey,
    'light-grey': $light-grey,
    'footer-grey': $footer-grey,
    'transparent': $transparent,
    'temp-yellow': $temp-yellow,
    'off-white': $off-white
);

/*--------------------------------------------------------------
Others
--------------------------------------------------------------*/
.border {
    border: 1px solid transparent;
    &-top {
        border-top: 1px solid transparent;
    }
    &-left {
        border-left: 1px solid transparent;
    }
    &-right {
        border-right: 1px solid transparent;
    }
    &-bottom {
        border-bottom: 1px solid transparent;
    }
}

@each $name, $color in $colors {
    .#{$name} {
        color: $color;
    }
    .bg--#{$name} {
        background-color: $color;
    }
    .border--#{$name} {
        border-color: $color;
    }
    .hover--#{$name} {
        &:hover {
            color: $color;
        }
    }
    .btn-hover--#{$name} {
        &:hover {
            background-color: $color;
        }
    }
}

/*--------------------------------------------------------------
// TEMPORARY DEMO GARBAGE -> PLEASE DELETE
--------------------------------------------------------------*/

// .bg--temp-yellow {
//     padding: 10px;
//     background-color: $stone-black;
//     color: $salt-white;
//     width: 100vw;
//     transform: translateX(-50%);
//     position: relative;
//     left: 50%;
//     text-align: center;
//     margin-top: 1rem;
    
// }